import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import * as types from '../constants/prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import BasicLayout from './basic-layout';
import {
  OrderSourceConfig,
  OrderConfirmation,
  SendMoneyTransactionCompleted,
} from '../components/send-money/wizard/index';
import { getRate, setField, clearTransaction, fetchHandle, cancelOrderPayment, setFieldsFromQueryParams } from '../actions/send-money-actions';
import CONST from '../constants/send-money-constants';
import Loader from '../components/loader';
import SignupLayout from './sign-up-layout';
import Svg from '../components/svg';
import Button from '../components/button';
import { hasPermission, permissionAllowedLimit, permissionErrorMessage } from '../utils/permissions';
import { currencyFormat } from '../utils/send-money-utils';
import { redirectToInvoiceComplete } from '../utils/redirects';

// Test invoice request
// eslint-disable-next-line max-len
// http://localhost:3000/order/form?type=invoice&status=pending&dest_wallet_id=gunbroker&amount[ordinal]=1&amount[decimal]=23&amount[currency]=FC&description=Phone+services+at+ACME+Inc&order_token=Fr73Bhs879mmUDs345r8Bfrls921&attributes=%7B%22amount%22%3A1.23%2C%22product%22%3A%22a234fd1%22%2C%22tax%22%3A0.07%2C%22BR_invoice_ticket_%22%3A%22d7g8v448f9dkm9FRU83%22%7D
// eslint-disable-next-line max-len
// http://localhost:3000/order/form?type=order&status=pending&dest_wallet_id=gunbroker&amount[ordinal]=1&amount[decimal]=23&amount[currency]=FC&description=Phone+services+at+ACME+Inc&order_token=Fr73Bhs879mmUDs345r8Bfrls921&attributes=%7B%22amount%22%3A1.23%2C%22product%22%3A%22a234fd1%22%2C%22tax%22%3A0.07%2C%22BR_invoice_ticket_%22%3A%22d7g8v448f9dkm9FRU83%22%7D
// http://localhost:3000/v1/pay/m7tTr32m214D
// Request for completed invoice
// eslint-disable-next-line max-len
// http://localhost:3000/orders/form?type=invoice&status=complete&dest_wallet_id=321frc45bskut67gev89&amount[ordinal]=1&amount[decimal]=23&amount[currency]=BRSC&description=Phone+services+at+ACME+Inc&transaction_id=3324djdue737dg373gd6
// eslint-disable-next-line max-len
// http://localhost:3000/orders/form?type=order&status=complete&dest_wallet_id=gunbroker&amount[ordinal]=11&amount[decimal]=17&amount[currency]=FC&description=Phone+services+at+ACME+Inc&transaction_id=355d5apkeuzhg8zioslk

const Payment = (props) => {
  useEffect(() => {
    const { isLoading, foundWallet, destinationWalletHandle } = props.sendMoney;
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if(queryParams && queryParams.clientui) {
      localStorage.setItem('clientui', queryParams.clientui);
    }

    if(queryParams && queryParams.amount) {
      if(queryParams.status === 'complete') {
        redirectToInvoiceComplete();
      }
      props.setFieldsFromQueryParams(queryParams);
    } else if (destinationWalletHandle && !foundWallet && !isLoading) {
      props.fetchHandle();
    }

    return () => {
      if (!props.sendMoney.isPayment) {
        props.clearTransaction();
      }
    };
  }, []);

  const { cancelOrderPayment } = props;
  const { user } = props.user;
  const {
    wizardStep,
    transactionRate,
    foundWallet,
    paymentProcessing,
    isLoading,
    isPayment,
    paymentAmount,
    isInvoice
  } = props.sendMoney;
  const { accounts } = props.wallet;
  const maxOrderAmount = permissionAllowedLimit(user.enabled_states, 'max_order_amount');

  const AnotherPaymentMethodButton = () => 
    isInvoice ? (
      <div className="page_controls"></div>
    ) : (
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={cancelOrderPayment}
        >
          Choose other payment method
        </Button>
      </div>
    );

  if (isLoading || paymentProcessing || !accounts || accounts.length === 0) {
    return (
      <div className="page -basic">
        <div className="page_wrapper -y-full -content-x-centered -content-y-centered">
          <div className="page_wrapper-inner">
            <div className="page_body">
              <div className="page_content">
                <Loader color="blue" size="sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isPayment && !isLoading && (!user.identity_verified || !user.email_verified)) {
    return (
      <SignupLayout
        headerClassName="-y-lg"
        wrapperClassName="-x-sm -content-y-centered -no-page-header"
        poweredByType="full"
      >
        <div className="page_body">
          <div className="page_content">
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="splash_title">
                  There is a problem with<br />your&nbsp;account
              </h1>
              <div className="splash_message">
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                  {!user.identity_verified
                    ? <span>You need to verify your identity <br /> in order to complete a transaction.</span>
                    : <span>You need to verify your email <br /> in order to complete a transaction.</span>
                  }
                </p>
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                  <a href="/login" target="_blank">Log in to {process.env.REACT_APP_DOMAIN_STRING} </a>
                    for further instructions
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnotherPaymentMethodButton />
      </SignupLayout>
    );
  }

  if (isPayment && !isLoading && !hasPermission(user.enabled_states, 'resides_in_allowed_state')) {
    return (
      <SignupLayout
        headerClassName="-y-lg"
        wrapperClassName="-x-sm -content-y-centered -no-page-header"
        poweredByType="full"
      >
        <div className="page_body">
          <div className="page_content">
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="splash_title">
                  Payment is not allowed
              </h1>
              <div className="splash_message">
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                  {permissionErrorMessage(user.enabled_states, 'resides_in_allowed_state')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnotherPaymentMethodButton />
      </SignupLayout>
    );
  }

  if (isPayment && !isLoading && (!foundWallet || foundWallet.status === 'suspended')) {
    return (
      <SignupLayout
        headerClassName="-y-lg"
        wrapperClassName="-x-sm -content-y-centered -no-page-header"
        poweredByType="full"
      >
        <div className="page_body">
          <div className="page_content">
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="splash_title">
                  There is a problem with<br />seller&rsquo;s&nbsp;account
              </h1>
              <div className="splash_message">
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                    Please contact your seller for further instructions
                  <br />
                    or use another payment method.
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnotherPaymentMethodButton />
      </SignupLayout>
    );
  }

  if (isPayment && !isLoading && !hasPermission(user.enabled_states, 'can_order')) {
    return (
      <SignupLayout
        headerClassName="-y-lg"
        wrapperClassName="-x-sm -content-y-centered -no-page-header"
        poweredByType="full"
      >
        <div className="page_body">
          <div className="page_content">
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="splash_title">
                  There is a problem with<br />your&nbsp;account
              </h1>
              <div className="splash_message">
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                  {permissionErrorMessage(user.enabled_states, 'can_order')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnotherPaymentMethodButton />
      </SignupLayout>
    );
  }

  if (isPayment && !isLoading && (parseFloat(maxOrderAmount) < paymentAmount)) {
    return (
      <SignupLayout
        headerClassName="-y-lg"
        wrapperClassName="-x-sm -content-y-centered -no-page-header"
        poweredByType="full"
      >
        <div className="page_body">
          <div className="page_content">
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="splash_title">
                  Payment is not allowed
              </h1>
              <div className="splash_message">
                <p className="font-size-primary-sm color-light-gray font-weight-regular">
                    Unfortunately, the total of this order<br />
                    exceeds your ${currencyFormat(maxOrderAmount)} maximum allowed<br />
                    for a single transaction.
                    Find all transactions limits in your {process.env.REACT_APP_DOMAIN_STRING} profile.  
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnotherPaymentMethodButton />
      </SignupLayout>
    );
  }

  return (
    <BasicLayout
      hideNav
      isPaymentLogo={true}
      headerClassName="-payment"
      innerWrapperClassName={
        cn(isPayment && '-small-header',)
      }
      wrapperClassName={
        cn(wizardStep === CONST.STEP_COMPLETED && '-content-y-centered -content-x-centered -x-xs -no-page-header',)
      }
      isBranding={true}
      poweredByType="full"
    >
      {transactionRate && foundWallet && (
        <Fragment>
          {wizardStep === CONST.STEP_SOURCE_CONFIG && (
            <OrderSourceConfig />
          )}
          {wizardStep === CONST.STEP_CONFIRMATION && (
            <OrderConfirmation />
          )}
          {wizardStep === CONST.STEP_COMPLETED && (
            <SendMoneyTransactionCompleted />
          )}
        </Fragment>
      )}
    </BasicLayout>
  );
};

Payment.propTypes = {
  clearTransaction: PropTypes.func.isRequired,
  fetchHandle: PropTypes.func.isRequired,
  cancelOrderPayment: PropTypes.func,
  sendMoney: PropTypes.shape(types.sendMoneyReducerTypes),
  user: PropTypes.shape(types.userReducerTypes),
  wallet: PropTypes.shape(types.walletReducerTypes),
  setFieldsFromQueryParams: PropTypes.func
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  user: state.user,
  wallet: state.wallet,
});

export default connect(mapStateToProps, {
  getRate,
  fetchHandle,
  setField,
  cancelOrderPayment,
  clearTransaction,
  setFieldsFromQueryParams
})(Payment);
