export const CONSTANTS = {
  SET_FIELD: '@card_account/SET_FIELD',
  CLEAR_FORM: '@card_account/CLEAR_FORM',
  SET_VALIDATION_ERROR: '@card_account/SET_VALIDATION_ERROR',
  GET_CARD_DETAILS: '@card_account/GET_CARD_DETAILS',
  GET_CARD_ACCOUNTS: '@card_account/GET_CARD_ACCOUNTS',
  UPDATE_CARD_ACCOUNT: '@card_account/UPDATE_CARD_ACCOUNT',
  REMOVE_CARD_ACCOUNT: '@card_account/REMOVE_CARD_ACCOUNT',
  ADD_CARD_ACCOUNT: '@card_account/ADD_CARD_ACCOUNT',
  GET_CARD_SERVICE: '@card_account/GET_CARD_SERVICE',
};

export const CARD_ICONS = {
  'visa': 'card-type-visa',
  'cartes-bancaires': 'card-type-cartes-bancaires',
  'mastercard': 'card-type-mastercard',
  'mc': 'card-type-mastercard',
  'maestro': 'card-type-maestro',
  'cirrus': 'card-type-cirrus',
  'american express': 'card-type-amex',
  'discover': 'card-type-discover',
  'diners-club': 'card-type-diners-club',
  'elo': 'card-type-elo',
  'hipercard': 'card-type-hipercard',
  'union-pay': 'card-type-union-pay',
  'DEFAULT': 'card-type-default'
};

export const CARD_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  SUSPENDED: 'suspended',
  DELETED: 'deleted',
  VERIFICATION_FAILED: 'failed'
};

export const getStatusColor = (status) => {
  switch (status) {
    case CARD_STATUS.EXPIRED:
    case CARD_STATUS.VERIFICATION_FAILED:
    case CARD_STATUS.SUSPENDED:
    case CARD_STATUS.DELETED:
      return 'color-red';
    case CARD_STATUS.PENDING:
      return 'color-orange';
    case CARD_STATUS.ACTIVE:
      return 'color-green';
    default:
      return '';
  }
};

export const getIconName = (cardType) => {
  const _cardType = cardType ? cardType.toLowerCase() : '';
  return CARD_ICONS[_cardType] ? CARD_ICONS[_cardType] : CARD_ICONS.DEFAULT;
};


export const detectCardType = (cardNumber) => {
  const sanitizedNumber = cardNumber.replace(/[\s-]/g, '');

  const visaRegEx = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegEx = /^5[1-5][0-9]{14}$/;
  const amexRegEx = /^3[47][0-9]{13}$/;
  const discoverRegEx = /^6(?:011|5[0-9]{2})[0-9]{12}$/;

  if (visaRegEx.test(sanitizedNumber)) {
    return 'Visa';
  } else if (mastercardRegEx.test(sanitizedNumber)) {
    return 'Mastercard';
  } else if (amexRegEx.test(sanitizedNumber)) {
    return 'American Express';
  } else if (discoverRegEx.test(sanitizedNumber)) {
    return 'Discover';
  } else {
    return 'Another';
  }
};

export default CONSTANTS;
