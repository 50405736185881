import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CONST from '../constants/recover-password-constants';
import LoginLayout from '../pages/log-in-layout';
import Button from '../components/button';
import Svg from '../components/svg';
import { formatTime } from '../utils/string-utils';

const expiration = 900;

const RecoverPasswordSent = ({ setField }) => {
  const initialMinute = parseInt(expiration / 60, 10);
  const initialSeconds = expiration % 60;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [ seconds, setSeconds ] =  useState(initialSeconds);

  useEffect( () => {
    const startTime = new Date().getTime();

    let timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const diff = Math.ceil((currentTime - startTime)/1000);
      const secondsLeft = (seconds || 59) - diff % 60;
      const minutesLeft = Math.floor((minutes * 60 - diff) / 60);

      if (startTime + expiration * 1000 - currentTime <= 0) {
        clearInterval(timer);
        setField('step', CONST.STEP_ERROR_LINK_EXPIRED);
      }

      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 100);

    return function() {
      clearInterval(timer);
    };
  }, []);

  return (
    <LoginLayout
      headerClassName="-y-xl"
      wrapperClassName="-x-sm -content-y-centered -page-control-bottom -no-page-header"
      twoLiner
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="letter-with-arrow" className="splash_icon" />
            <div className="splash_message -space-down-sm">
              We've sent you an email with a link to reset your password
            </div>
            <div className="splash_message layer -space-up-md">
              Follow the link in the email to complete password recovery
            </div>
            <div className="layer -space-up-xl splash_message">Link expires in {formatTime(minutes)}:{formatTime(seconds)}</div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -no-border -cancel font-weight-bold"
          href="login"
        >
          Back to login
        </Button>
      </div>
    </LoginLayout>
  );
};

RecoverPasswordSent.propTypes = {
  setField: PropTypes.func,
};

export default RecoverPasswordSent;
