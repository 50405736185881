import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { setField } from '../actions/transfer-actions';
import DashboardLayout from './dashboard-layout';
import DashboardNotifications from './dashboard-notifications';
import { history } from '../init-store';
import { fetchAccounts } from '../actions/wallet-actions';
import { getUserSimple, updateUserNotice } from '../actions/user-actions';
import { permissionSelector } from '../reducers/user-reducer';
import AccountWidgets from '../components/accounts-widget';
import SmartTransactionsList from '../components/transactions-list-smart';
import { userBalanceSelector } from '../reducers/wallet-reducer';
import { currencyOptionsSelector, isSendMoneyDisabledSelector, isTransferDisabledSelector } from '../reducers/app-reducer';

const Dashboard = (props) => {

  useEffect(() => {
    props.fetchAccounts();
    props.getUserSimple(props.user.user.user_id);
  }, []);

  const predefineTransfer = (type) => {
    props.setField('predefined', 'transfer');
    history.push('/transfer');
  };

  const { userBalance, transactionRate, accounts, currencyOptions, isTransferDisabled, isSendMoneyDisabled } = props;

  return (
    <DashboardLayout>
      <div className="page_body">
        <div className="page_content">
          <div className="form dashboard">
            <DashboardNotifications />
            <div className="layer">
              <AccountWidgets
                accounts={accounts}
                rates={transactionRate}
                onTransfer={() => predefineTransfer()}
                currencyOptions={currencyOptions}
                userBalance={userBalance}
                isSendMoneyDisabled={isSendMoneyDisabled}
                isTransferDisabled={isTransferDisabled}
              />
            </div>

            <SmartTransactionsList />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

Dashboard.propTypes = {
  accounts: types.walletReducerTypes.accounts,
  bankAccounts: types.walletReducerTypes.bankAccounts,
  user: PropTypes.shape(types.userReducerTypes),
  fetchAccounts: PropTypes.func,
  setField: PropTypes.func,
  getUserSimple: PropTypes.func,
  userBalance: PropTypes.string,
  transactionRate: types.RatesShape,
  currencyOptions: PropTypes.arrayOf(types.CurrencyOptionShape),
  isSendMoneyDisabled: PropTypes.bool, 
  isTransferDisabled: PropTypes.bool
};

const mapStateToProps = (state) => {
  const { wallet, user, application: { options } } = state;
  const { accounts, bankAccounts, transactionRate } = wallet;

  const permissions = permissionSelector(state);
  const userBalance = userBalanceSelector(state);
  const currencyOptions = currencyOptionsSelector(state);
  const isSendMoneyDisabled = isSendMoneyDisabledSelector(state);
  const isTransferDisabled = isTransferDisabledSelector(state);

  return { 
    accounts, bankAccounts, user, transactionRate, permissions, options, userBalance, currencyOptions, isSendMoneyDisabled, isTransferDisabled
  };
};

export default connect(mapStateToProps, {
  setField,
  fetchAccounts,
  getUserSimple,
  updateUserNotice,
})(Dashboard);