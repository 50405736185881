import React from 'react';
import PropTypes from 'prop-types';

import LoginLayout from '../pages/log-in-layout';
import Button from '../components/button';
import FormInput from '../components/form-input';


const RecoverPassword = props => (
  <LoginLayout
    bgClassName="-full"
    headerClassName="-y-xl"
    wrapperClassName="-x-sm -content-y-centered -page-control-bottom"
    twoLiner
  >
    <div className="page_header -x-centered-desktop">
      <h1 className="page_title">Recover Password</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <div className="form">
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                inputName="email"
                label="Email"
                type="email"
                transparency="half"
                value={props.recoverPasswordData.email}
                className="full-width"
                onChange={value => props.setField('email', value)}
                errorMessage={props.recoverPasswordData.validationErrors.email}
                invalid={!!props.recoverPasswordData.validationErrors.email}
              />
            </div>
          </div>
          <div className="layer -space-up-lg">
            <p className="font-size-secondary-responsive">
              Please enter the email used for registration. We&nbsp;will send you a&nbsp;link with
              further instructions on&nbsp;how to&nbsp;recover your password.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="page_controls">
      <Button
        xSize="full"
        transparency="full"
        className="page_control -no-border -cancel font-weight-bold"
        href="/login"
      >
        Back to Log In
      </Button>
      <Button
        color="blue"
        xSize="full"
        className="page_control
        -submit"
        onClick={props.requestResetLink}
        disabled={!props.recoverPasswordData.email}
        loading={props.recoverPasswordData.isLoading}
      >
        Continue
      </Button>
    </div>
  </LoginLayout>
);

RecoverPassword.propTypes = {
  recoverPasswordData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    validationErrors: PropTypes.shape({
      email: PropTypes.string
    }),
    isLoading: PropTypes.bool.isRequired,
  }),
  setField: PropTypes.func.isRequired,
  requestResetLink: PropTypes.func.isRequired,
};

export default RecoverPassword;
