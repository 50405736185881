import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/button';
import Modal from '../../components/modal';
import FormInput from '../../components/form-input';

import VALIDATION from '../../utils/validation';

const CreditCardLabel = (props) => {
  const [validationError, setValidationError] = useState(null);

  const closeModal = () => {
    props.onClose();
    setValidationError(null);
  };

  const onFieldChange = (value) => {
    props.setField(['cardDetails', 'user_description'], value);
    setValidationError(null);
  };

  const saveCardLabel = (cardDetailsLabel) => {
    const cardDetailsLabelValidation = VALIDATION.validateAccountLabel(cardDetailsLabel, 'Card label');
    if (cardDetailsLabelValidation.success) {
      props.updateCardAccount(props.cardDetails.card_account_id, { user_description: cardDetailsLabel }, () => props.onClose());
    } else {
      setValidationError(cardDetailsLabelValidation.message);
    }
  };

  const accountLabel = props.cardDetails.user_description || '';

  return (
    <Modal {...props}>
      <div className="modal_header">
        <h1 className="js-modal-title">Update card label</h1>
      </div>
      <div className="modal_body">
        <div className="form">
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="account-label"
                label="Card label"
                value={accountLabel}
                className="full-width"
                onPressEnter={() => saveCardLabel(accountLabel)}
                onChange={onFieldChange}
                errorMessage={validationError}
                invalid={!!validationError}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            disabled={props.isLoading}
            onClick={closeModal}
          >
              Cancel
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button modal_footer-control -submit"
            onClick={() => saveCardLabel(accountLabel)}
            disabled={props.isLoading || validationError}
            loading={props.isLoading}
          >
            Update Card
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CreditCardLabel.propTypes = {
  cardDetails: PropTypes.shape({
    card_account_id: PropTypes.string,
    user_description: PropTypes.string,
  }),
  updateCardAccount: PropTypes.func,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  setField: PropTypes.func
};

CreditCardLabel.defaultProps = {
  cardDetails: {
    user_description: '',
  },
  isLoading: false,
  setField: () => {}
};

export default CreditCardLabel;
