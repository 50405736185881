import { takeLatest, put, select, take, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { forEach, filter, evolve, is, startsWith, pipe, propOr, toPairs, map, split, prop, merge, path } from 'ramda';
import CONST from '../constants/send-money-constants';
import RECURRENT_CONST from '../constants/recurrent-payment-constants';
import REQUESTS from '../utils/requests';
import {
  setSearchedWallet,
  setRate,
  setTransaction,
  setErrorMessage,
  setField,
  setFields,
} from '../actions/send-money-actions';
import { setField as setRecurrentField } from '../actions/recurrent-payment-actions';
import { setAppOpenedInPopup } from '../actions/app-actions';
import { truncatedValue, getConvertibleTotal, _convertCurrency, isBackupCardError, balance } from '../utils/send-money-utils';
import VALIDATION from '../utils/validation';
import Decimal from 'decimal.js';

function getOrderSource (sendMoney) {
  const {
    paymentAccountId,
    paymentCurrency,
    rewardsUsed,
    rewardsSource,
    accLeft,
    paymentSourceType,
  } = sendMoney;

  const paymentSources = {};
  const convertedValue = accLeft;

  switch (paymentSourceType) {
    case 'accounts': 
      paymentSources.accounts = [];

      paymentSources.accounts.push({
        account_id: paymentAccountId,
        amount: {
          ordinal: convertedValue.split('.')[0],
          decimal: convertedValue.split('.')[1] || '00',
          currency: paymentCurrency,
        },
      });

      break;
    case 'banksAvailabilty': 
      paymentSources.bank_accounts = [];

      paymentSources.bank_accounts.push({
        bank_account_id: paymentAccountId,
        amount: {
          ordinal: convertedValue.split('.')[0],
          decimal: convertedValue.split('.')[1] || '00',
          currency: paymentCurrency,
        },
      });
      
      break;
    case 'recentTransfers': 
      paymentSources.transactions = [];

      paymentSources.transactions.push({
        transaction_id: paymentAccountId,
        amount: {
          ordinal: convertedValue.split('.')[0],
          decimal: convertedValue.split('.')[1] || '00',
          currency: paymentCurrency,
        },
      });

      break;
    default: break;
  }

  if (rewardsUsed && rewardsSource && rewardsSource.reward_id) {
    paymentSources.rewards = [];
      
    paymentSources.rewards.push({
      reward_id: rewardsSource.reward_id,
      amount: {
        ordinal: rewardsSource.amount.ordinal,
        decimal: rewardsSource.amount.decimal,
        currency: rewardsSource.amount.currency,
      },
    });
  }

  return paymentSources;
}


function mapPaymentSource(sources, transactionCurrency, transactionRate, selectedSource) {
  const accounts = [];
  const bankAccounts = [];
  const bankSources = filter(s => s.type === 'bank', sources);
  const accountSources = filter(s => s.type === 'account', sources);
  forEach((bankSource) => {
    bankAccounts.push({
      bank_account_id: bankSource.id,
      amount: {
        ordinal: bankSource.amount.split('.')[0],
        decimal: bankSource.amount.split('.')[1] || '00',
        currency: bankSource.currency,
      },
    });
  })(bankSources);
  forEach((accSource) => {
    if (accSource.currency !== transactionCurrency) {
      const convertedValue = _convertCurrency(accSource.currency, transactionCurrency, accSource.amount, transactionRate);
      accounts.push({
        account_id: accSource.id,
        amount: {
          ordinal: convertedValue.split('.')[0],
          decimal: convertedValue.split('.')[1] || '00',
          currency: transactionCurrency,
        },
      });
    } else {
      accounts.push({
        account_id: accSource.id,
        amount: {
          ordinal: accSource.amount.split('.')[0],
          decimal: accSource.amount.split('.')[1] || '00',
          currency: accSource.currency,
        },
      });
    }
  })(accountSources);

  if(selectedSource && selectedSource.reward_id) {
    if (selectedSource.amount.currency !== transactionCurrency) {
      const convertedReward = _convertCurrency(
        selectedSource.amount.currency,
        transactionCurrency, 
        selectedSource.rewardAmountTransactionCurrency,
        transactionRate
      );

      return {
        accounts: evolve(accounts, accounts),
        bank_accounts: evolve(bankAccounts, bankAccounts),
        rewards: [{
          reward_id: selectedSource.reward_id,
          amount: {
            ordinal: convertedReward.split('.')[0],
            decimal: convertedReward.split('.')[1] || '00',
            currency: transactionCurrency,
          },
        }]
      };
    } else {
      return {
        accounts: evolve(accounts, accounts),
        bank_accounts: evolve(bankAccounts, bankAccounts),
        rewards: [{
          reward_id: selectedSource.reward_id,
          amount: {
            ordinal: selectedSource.amount.ordinal,
            decimal: selectedSource.amount.decimal,
            currency: selectedSource.amount.currency,
          },
        }]
      };
    }
  }
  
  return {
    accounts: evolve(accounts, accounts),
    bank_accounts: evolve(bankAccounts, bankAccounts),
  };
}

function* getWalletBySearchFlow({ payload }) {
  try {
    const { success, data } = yield REQUESTS.GET_WALLET_BY_SEARCH_PARAM(payload.searchString);

    yield put(setField('isLoading', false));

    if (success) {
      if (data.length === 1) {
        const [wallet] = data;
  
        if (wallet.status === 'suspended' || !wallet.identity_verified) {
          yield put(setErrorMessage('This wallet is not receiving funds at the moment'));
        } else {
          yield put(setSearchedWallet(wallet));
        }
      } else {
        if (data.length === 0) {
          yield put(setErrorMessage('No wallets found'));
        } else {
          const { type, rest } = VALIDATION.searchStringSource(payload.searchString);

          yield put(setErrorMessage(`Unable to use this ${type} for payment. Please use ${rest}`));
        }
      }
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* getTransactionRate({ payload }) {
  try {
    const { success, data } = yield REQUESTS.GET_RATE();

    if (success) {
      yield put(setRate(data[0], payload.dontCleanErorrs));
      
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* saveRecurrentPayment() {
  try {
    const { wallet, recurrentPayment } = yield select(state => state);
    yield put(setRecurrentField('paymentInProcess', true));

    const convertedValue = _convertCurrency(recurrentPayment.currency, 'USD', recurrentPayment.amount, wallet.transactionRate);
    const amount = truncatedValue(convertedValue).split('.');
    const payment = {
      dest_wallet_id: recurrentPayment.destWalletHandle,
      source_wallet_id: wallet.wallet.wallet_id,
      source_wallet_handle: wallet.wallet.handle,
      source_backup_account_id: recurrentPayment.selectedBankAccount.bank_account_id,
      max_amount: {
        decimal: amount[1],
        ordinal: amount[0],
        currency: recurrentPayment.currency,
      },
      frequency: recurrentPayment.frequency,
      agreement_token: recurrentPayment.agreementToken,
    };

    if (recurrentPayment.usdAccIsUsed) {
      payment.source_preferred_account_id = recurrentPayment.selectedAccount.account_id;
    }

    const { success, data } = yield call(REQUESTS.SAVE_RECURRENT_PAYMENT, payment);

    if (success) {
      const parentData = {
        status: 'success',
        fc_agreement_id: data[0].payment_agreement_id,
        verification_token: data[0].agreement_token,
        source_wallet_id: data[0].source_wallet_id,
        source_wallet_handle: data[0].source_wallet_handle,
      };
      yield call(sendToParent, parentData, recurrentPayment.eventId);
    }

    yield put(setRecurrentField('paymentInProcess', false));
  } catch (e) {
    console.log(e);
    yield put(setRecurrentField('paymentInProcess', false));
  }
}

function* payOrder() {
  yield put(setField('isLoading', true));
  const { sendMoney, wallet } = yield select(state => state);
  const base = { order_token: sendMoney.orderToken, attributes: sendMoney.attributes };

  const payment = {
    ...base,
    source_wallet_id: wallet.wallet.wallet_id,
    dest_wallet_id: sendMoney.foundWallet.wallet_id,
    amount: {
      ordinal: sendMoney.paymentAmount.split('.')[0],
      decimal: sendMoney.paymentAmount.split('.')[1] || '00',
      currency: sendMoney.paymentCurrency,
    },
    source: getOrderSource(sendMoney, wallet),
    description: sendMoney.paymentNotes || sendMoney.orderDescription,
    transaction_rate_id: sendMoney.transactionRate.rate_id,
    backup_card_id: sendMoney.backup_card_id
  };

  try {
    const result = yield call(REQUESTS.MAKE_ORDER_PAYMENT, payment);
    const { success, data, errors } = result;

    if (success) {
      yield put(setTransaction(data[0]));
      yield call(successOrderPayment, data[0]);
      yield call([window, window.close]);
    } else if(errors && isBackupCardError(errors)) {
      // send to first step
      // open backup error modal
      yield put(setField('wizardStep', CONST.STEP_SOURCE_CONFIG));
      yield put(setField('isCardErrorModalOpen', true));
    } 

    yield put(setField('isLoading', false));
  } catch (e) {
    console.log(e);
    yield put(setField('isLoading', false));
  }
}

function* getRefund(transactionId) {
  yield put(setField('isLoading', true));

  const { sendMoney } = yield select(state => state);

  const payment = {
    amount: {
      ordinal: sendMoney.paymentAmount.split('.')[0],
      decimal: sendMoney.paymentAmount.split('.')[1] || '00',
      currency: sendMoney.paymentCurrency,
    },
    reason: sendMoney.paymentNotes,
  };

  try {
    const { data, success } = yield REQUESTS.GET_REFUND(transactionId, payment);

    if (success) {
      yield put(setField('refundSources', data[0].refund_sources));
      yield put(setField('isLoading', false));
    } else {
      yield put(setField('error', 'Something went wrong. Please try again.'));
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('error', 'Something went wrong. Please try again.'));
    yield put(setField('isLoading', false));
  }
}

function* makeRefund(payload) {
  yield put(setField('isLoading', true));

  const { sendMoney } = yield select(state => state);
  const transactionId = payload.transaction.transaction_id;

  const payment = {
    amount: {
      ordinal: sendMoney.paymentAmount.split('.')[0],
      decimal: sendMoney.paymentAmount.split('.')[1] || '00',
      currency: sendMoney.paymentCurrency,
    },
    reason: sendMoney.paymentNotes
  };

  try {
    const { data, success } = yield REQUESTS.MAKE_REFUND(transactionId, payment);
    if (success) {
      yield put(setField('transactionResult', data[0]));
      yield put(setField('isLoading', false));
      yield put(setField('isRefund', true));
      yield put(setField('refundStep', CONST.STEP_COMPLETED));

    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* payPredefinedOrder() {
  yield put(setField('isLoading', true));
  const { sendMoney, wallet } = yield select(state => state);
  const base = { attributes: sendMoney.attributes };

  const payment = {
    ...base,
    source_wallet_id: wallet.wallet.wallet_id,
    dest_wallet_id: sendMoney.foundWallet.wallet_id,
    amount: {
      ordinal: sendMoney.paymentAmount.split('.')[0],
      decimal: sendMoney.paymentAmount.split('.')[1] || '00',
      currency: sendMoney.paymentCurrency,
    },
    source: getOrderSource(sendMoney, wallet),
    description: sendMoney.paymentNotes,
    transaction_rate_id: sendMoney.transactionRate.rate_id,
    order_token: 'Retry Order'
  };

  try {
    const result = yield call(REQUESTS.MAKE_ORDER_PAYMENT, payment);
    const { success, data } = result;

    if (success) {
      yield put(setTransaction(data[0]));
      yield put(setField('wizardStep', CONST.STEP_ORDER_SUCCESS));
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* makePayment() {
  const { sendMoney, wallet } = yield select(state => state);
  const sources = mapPaymentSource(
    sendMoney.paymentSource,
    sendMoney.paymentCurrency,
    sendMoney.transactionRate,
    sendMoney.paymentBonusSource.selectedSource,
  );

  const { isPayment } = sendMoney;

  const base = isPayment
    ? { order_token: sendMoney.orderToken, attributes: sendMoney.attributes }
    : {};

  const payment = {
    ...base,
    source_wallet_id: wallet.wallet.wallet_id,
    dest_wallet_id: sendMoney.foundWallet.wallet_id,
    amount: {
      ordinal: sendMoney.paymentAmount.split('.')[0],
      decimal: sendMoney.paymentAmount.split('.')[1] || '00',
      currency: sendMoney.paymentCurrency,
    },
    source: sources,
    description: sendMoney.paymentNotes,
    transaction_rate_id: sendMoney.transactionRate.rate_id,
  };

  try {
    if (isPayment) {
      const result = yield call(REQUESTS.MAKE_ORDER_PAYMENT, payment);
      const { success, data } = result;

      if (success) {
        yield put(setTransaction(data[0]));

        yield call(successOrderPayment, data[0]); // eslint-disable-line

        yield call([window, window.close]);
      } else {
        yield put(setField('isLoading', false));
      }
    } else {
      const { success, data } = yield call(REQUESTS.MAKE_PAYMENT, payment);

      if (success) {
        yield put(setTransaction(data[0]));
      } else {
        yield put(setField('isLoading', false));
      }
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

const sendToParent = function* (data, eventId) {
  const jsonData = JSON.stringify(data);
  const parent = window.opener || window.parent;

  yield call([parent, parent.postMessage], `fc.evt:iframe:${eventId}|${jsonData}`, '*');

  // for popup window
  yield call([window, window.close]);
};

const successOrderPayment = function* (transaction) {
  const { destinationWalletHandle, eventId } = yield select(prop('sendMoney'));

  try {
    const data = {
      status: 'success',
      dest_wallet_handle: destinationWalletHandle,
      fc_transaction_id: transaction.transaction_id,
      verification_token: transaction.verification_token,
    };

    yield call(sendToParent, data, eventId);
  } catch (e) {
    // eslint-disable-next-line no-use-before-define
    const data = merge(e.data, { dest_wallet_handle: destinationWalletHandle });
    yield call(sendToParent, data, eventId);
  }
};

const cancelRecurrentPayment = function* () {
  const { agreementToken, eventId } = yield select(prop('recurrentPayment'));

  try {
    const data = {
      status: 'cancelled',
      agreement_token: agreementToken,
      code: 'create_agreement:user_cancelled',
    };

    yield call(sendToParent, data, eventId);
  } catch (e) {
    console.log(e);
  }
};

const cancelOrderPayment = function* () {
  const { destinationWalletHandle, eventId } = yield select(prop('sendMoney'));

  const data = {
    status: 'cancelled',
    dest_wallet_handle: destinationWalletHandle,
    code: 'order:user_cancelled',
  };

  yield call([window, window.close]);
  

  yield call(sendToParent, data, eventId);
};

const closeOrderPayment = function* () {
  const { eventId } = yield select(prop('sendMoney'));
  const jsonData = JSON.stringify({
    status: 'closed',
    code: 'order:closed',
  });
  const parent = window.opener || window.parent;

  yield call([parent, parent.postMessage], `fc.evt:popup:${eventId}|${jsonData}`, '*');
};

const createMessageEventListenerChannel = () => eventChannel((emit) => {
  window.addEventListener('message', emit, false);

  return () => {
    window.removeEventListener('message', emit);
  };
});


const fetchHandle = function* () {
  const { destinationWalletHandle } = yield select(prop('sendMoney'));

  yield put(setField('paymentProcessing', true));
  const { success, data } = yield call(REQUESTS.GET_WALLET_BY_SEARCH_PARAM, destinationWalletHandle);
  const { success: rateSuccess, data: rateData } = yield REQUESTS.GET_RATE();

  if (success && rateSuccess) {
    if (data.length === 1) {
      yield put(setField('paymentProcessing', false));
      const [wallet] = data;

      if (wallet.status === 'suspended' || !wallet.identity_verified) {
        yield put(setErrorMessage('This wallet is not receiving funds at the moment'));
      } else {
        yield put(setRate(rateData[0]));
        yield put(setSearchedWallet(wallet));
      }
    } else {
      yield put(setField('paymentProcessing', false));

      if (data.length === 0) {
        yield put(setErrorMessage('No wallets found'));
      } else {
        const { type, rest } = VALIDATION.searchStringSource(destinationWalletHandle);

        yield put(setErrorMessage(`Unable to use this ${type} for payment. Please use ${rest}`));
      }
    }
  }
};

const retryTransactionPreflight = function* ({ payload: transaction }) {
  const {
    attributes,
    destination: {
      wallet_id: dest_wallet_id,
    },
    source: {
      wallet_id: source_wallet_id,
    },
    net_amount: {
      currency,
      decimal,
      ordinal,
    },
    description,
  } = transaction;

  yield put(setFields({
    preflightIsLoading: true,
  }));


  const { success: rateSuccess, data: rateData } = yield REQUESTS.GET_RATE();

  if (rateSuccess) {
    yield put(setRate(rateData[0]));

    const orderPreflight = {
      transaction_rate_id: rateData[0].rate_id,
      attributes,
      source_wallet_id,
      dest_wallet_id,
      amount: {
        currency,
        decimal,
        ordinal,
      },
      description,
    };


    const { success: preflightSuccess, data: preflightData } = yield call(REQUESTS.GET_ORDER_PREFLIGHT, orderPreflight);

    if (preflightSuccess) {
      yield put(setFields({
        banksAvailabilty: preflightData[0].bank_availability || [],
        recentTransfers: preflightData[0].recent_transfers || [],
        preflightIsLoading: false,
      }));
    }
  }
};


const getPreflightAndHandle = function* () {
  const {
    destinationWalletHandle,
    paymentAmount,
    paymentNotes,
    paymentCurrency,
    orderToken,
    attributes
  } = yield select(prop('sendMoney'));

  const { wallet: currentWallet, user: { user: { user_id } } } = yield select(state => state);

  if(user_id) {
    yield put(setField('paymentProcessing', true));
    const { success, data } = yield call(REQUESTS.GET_WALLET_BY_SEARCH_PARAM, destinationWalletHandle);
    const { success: rateSuccess, data: rateData } = yield REQUESTS.GET_RATE();
  
    if (success && rateSuccess) {
      if (data.length === 1) {
        yield put(setField('paymentProcessing', false));
        const [wallet] = data;
  
        if (wallet.status === 'suspended' || !wallet.identity_verified) {
          yield put(setErrorMessage('This wallet is not receiving funds at the moment'));
        } else {
          yield put(setRate(rateData[0]));
          yield put(setSearchedWallet(wallet));
  
          const orderPreflight = {
            order_token: orderToken,
            attributes: attributes,
            source_wallet_id: currentWallet.wallet.wallet_id,
            dest_wallet_id: wallet.wallet_id,
            amount: {
              ordinal: paymentAmount.split('.')[0],
              decimal: paymentAmount.split('.')[1] || '00',
              currency: paymentCurrency,
            },
            description: paymentNotes,
            transaction_rate_id: rateData[0].rate_id,
          };
  
          yield put(setFields({
            preflightIsLoading: true,
          }));
  
          const { success: preflightSuccess, data: preflightData } = yield call(REQUESTS.GET_ORDER_PREFLIGHT, orderPreflight);
      
          if (preflightSuccess) {
            yield put(setFields({
              banksAvailabilty: preflightData[0].bank_availability || [],
              recentTransfers: preflightData[0].recent_transfers || [],
              preflightIsLoading: false,
            }));
          }
        }
      } else {
        yield put(setField('paymentProcessing', false));
  
        if (data.length === 0) {
          yield put(setErrorMessage('No wallets found'));
        } else {
          const { type, rest } = VALIDATION.searchStringSource(destinationWalletHandle);
  
          yield put(setErrorMessage(`Unable to use this ${type} for payment. Please use ${rest}`));
        }
      }
    }
  }
};


const initOrder = function* (eventId, orderData) {
  const handle = orderData.dest_wallet_handle || orderData.dest_vault_handle;
  const attributes = pipe(
    propOr({}, 'attributes'),
    toPairs,
    map(([key, value]) => ({ key, value })),
  )(orderData);

  orderData.description && attributes.unshift({ key: 'description', value: String(orderData.description) });

  yield put(setAppOpenedInPopup(true));

  yield put(setFields({
    eventId,
    isLoading: true,
    isPayment: true,
    paymentAmount: String(orderData.amount),
    currency: String(orderData.currency),
    paymentCurrency: String(orderData.currency),
    destinationWalletHandle: handle,
    orderToken: String(orderData.order_token),
    orderID: String(orderData.attributes.OrderID || '-'),
    orderDescription: String(orderData.description),
    wizardStep: CONST.STEP_SOURCE_CONFIG,
    attributes,
  }));

  yield call(getPreflightAndHandle);
};

const confirmRefund = function* ({ accounts, transaction }) {
  yield put(setField('isLoading', true));
  const {
    paymentAmount,
  } = yield select(prop('sendMoney'));

  try {
    const { success: rateSuccess, data: rateData } = yield REQUESTS.GET_RATE();

    if (rateSuccess) {
      const rate = rateData[0];
      const convertibleTotal = Decimal(
        accounts.reduce(
          (result, account) => Decimal(result).plus(
            !!path([account.balance.currency, 'USD'], rate) 
              ? getConvertibleTotal(account, rate) 
              : balance(account.balance.ordinal, account.balance.decimal, account.balance.sign)
          ), 0)
      ).toFixed(2);

      if (parseFloat(convertibleTotal) < parseFloat(paymentAmount)) {
        yield put(setField('error', 'Not enough founds in your BitRail accounts'));
        yield put(setField('isLoading', false));
      } else {
        yield put(setField('isLoading', false));
        yield put(setField('transactionRate', rate));
        yield call(getRefund, transaction.transaction_id);
        yield put(setField('refundStep', CONST.STEP_CONFIRMATION));
      }
    } else {
      yield put(setField('error', 'Something went wrong. Please try again.'));
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('error', 'Something went wrong. Please try again'));
    yield put(setField('isLoading', false));
  }
};

const waitForOrderEvent = function* () {
  const messageChannel = yield call(createMessageEventListenerChannel);

  while (true) {
    const event = yield take(messageChannel);

    if (is(String, event.data) && startsWith('fc.evt:', event.data)) {
      const [meta] = split('|', event.data);
      const [, eventType, eventId] = split(':', meta);

      if (eventType === 'fc.start_order') {
        try {
          const orderData = JSON.parse(event.data.substring(meta.length + 1));

          if(window.location.pathname !== '/signup' && !window.location.pathname.includes('/auth/')) {
            yield call(initOrder, eventId, orderData);
          }
        } catch (e) {
          yield put(setErrorMessage('Something went wrong'));
          console.log(e);
        }
      }

      if (eventType === 'fc.create_agreement') {
        try {
          const data = JSON.parse(event.data.substring(meta.length + 1));

          yield put(setRecurrentField('eventId', eventId));
          yield put(setRecurrentField('agreementToken', data.agreement_token));
          yield put(setRecurrentField('destWalletHandle', data.dest_wallet_handle || data.dest_vault_handle));
          yield put(setRecurrentField('frequency', data.frequency));
          yield put(setRecurrentField('amount', data.amount));
          yield put(setRecurrentField('currency', data.currency));
          yield put(setRecurrentField('description', data.description));

          const { success, data: rateData } = yield REQUESTS.GET_RATE();

          if (success) {
            yield put(setRecurrentField('rate', rateData[0]));
          }

          yield put(setRecurrentField('isLoading', false));
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};

const setFieldsFromQueryParams = function* ({ payload }) {
  const { queryParams } = payload;

  let attributes = {};
  
  if(queryParams.attributes) {
    attributes = pipe(
      toPairs,
      map(([key, value]) => ({ key, value })),
    )(Object.assign({ description: queryParams.description }, JSON.parse(queryParams.attributes)));
  }

  yield put(setFields({
    paymentAmount: `${queryParams.amount.ordinal}.${queryParams.amount.decimal}`,
    paymentCurrency: queryParams.amount.currency,
    attributes,
    orderDescription: queryParams.description,
    destinationWalletHandle: queryParams.dest_wallet_id || queryParams.dest_vault_id,
    orderToken: queryParams.order_token,
    paymentType: queryParams.type,
    wizardStep: CONST.STEP_SOURCE_CONFIG,
    isPayment: true,
    isInvoice: queryParams.type === 'invoice'
  }));

  yield call(getPreflightAndHandle);
};

function* makePreflight() {
  const {
    foundWallet,
    paymentAmount,
    paymentNotes,
    paymentCurrency,
    orderToken,
    attributes,
    transactionRate,
    backup_card_id
  } = yield select(prop('sendMoney'));

  

  const { wallet: currentWallet } = yield select(state => state);

  const orderPreflight = {
    order_token: orderToken,
    attributes: attributes,
    source_wallet_id: currentWallet.wallet.wallet_id,
    dest_wallet_id: foundWallet.wallet_id,
    amount: {
      ordinal: paymentAmount.split('.')[0],
      decimal: paymentAmount.split('.')[1] || '00',
      currency: paymentCurrency,
    },
    description: paymentNotes,
    transaction_rate_id: transactionRate.rate_id,
    backup_card_id
  };

  const { success: preflightSuccess, data: preflightData } = yield call(REQUESTS.GET_ORDER_PREFLIGHT, orderPreflight);
    
  if (preflightSuccess) {
    yield put(setFields({
      banksAvailabilty: preflightData[0].bank_availability || [],
      cardAvailability: preflightData[0].card_availability || [],
      recentTransfers: preflightData[0].recent_transfers || [],
      preflightIsLoading: false,
    }));
  }

}

function* sendMoneySagas() {
  yield takeLatest(CONST.GET_WALLET_BY_SEARCH, getWalletBySearchFlow);
  yield takeLatest(CONST.GET_RATE, getTransactionRate);
  yield takeLatest(CONST.MAKE_PAYMENT, makePayment);
  yield takeLatest(CONST.MAKE_REFUND, makeRefund);
  yield takeLatest(CONST.GET_REFUND, getRefund);
  yield takeLatest(CONST.CONFIRM_REFUND, confirmRefund);
  yield takeLatest(CONST.PAY_ORDER, payOrder);
  yield takeLatest(CONST.PAY_PREDEFINED_ORDER, payPredefinedOrder);
  yield takeLatest(CONST.FETCH_HANDLE, fetchHandle);
  yield takeLatest(CONST.CANCEL_ORDER_PAYMENT, cancelOrderPayment);
  yield takeLatest(RECURRENT_CONST.SAVE_RECURRENT_PAYMENT, saveRecurrentPayment);
  yield takeLatest(RECURRENT_CONST.CANCEL_PAYMENT, cancelRecurrentPayment);
  yield takeLatest(CONST.RETRY_PREFLIGHT, retryTransactionPreflight);
  yield takeLatest(CONST.SET_FIELDS_FROM_QUERY_PARAMS, setFieldsFromQueryParams);
  yield takeLatest(CONST.CLOSE_ORDER_PAYMENT, closeOrderPayment);
  yield takeLatest(CONST.MAKE_PREFLIGHT, makePreflight);
}

export default [sendMoneySagas, waitForOrderEvent];
