import React from 'react';
import PropTypes from 'prop-types';
import { CreditCardShape } from '../../constants/prop-types';
import Button from '../../components/button';
import Svg from '../../components/svg';
import { getIconName } from '../../constants/card-account-constants';

const BackupCardWidget = ({
  openChangeCardModal,
  openAddCardModal,
  backupCard
}) => {


  return backupCard ? backupCard.card_account_id ? (
    <Button
      xSize="full"
      ySize="xl"
      className="button-group_item order-backup__content"
      color="white"
      onClick={openChangeCardModal}
    >
      <div className="button_head">
        <div className="button_content-primary js-nickname">Presonal Card will be used as backup method</div>
        <div className="button_content-secondary">
          <Svg name={getIconName(backupCard.bank_name)} className="icon cards-page__icon" />
          <span className="account-number-bullets">&bull;&bull;</span>{backupCard.account_number}&nbsp;
        </div>
      </div>
      <div className="button_tail">
        Change card
      </div>
    </Button>
  ) : (
    <Button
      xSize="full"
      ySize="xl"
      className="button-group_item order-backup__content"
      color="white"
      onClick={openChangeCardModal}
    >
      <div className="button_head">
        <div className="button_content-primary">Continue with no backup</div>
      </div>
      <div className="button_tail">
        Change card
      </div>
    </Button>
  ) : (
    <Button
      xSize="full"
      ySize="xl"
      className="button-group_item order-backup__content"
      color="white"
      onClick={openAddCardModal}
    >
      <div className="button_head">
        <div className="button_content-primary">No backup payment method, add card for instant payment</div>
      </div>
      <div className="button_tail">
        Add card
      </div>
    </Button>
  );
};

BackupCardWidget.propTypes = {
  backupCard: CreditCardShape,
  openChangeCardModal: PropTypes.func,
  openAddCardModal: PropTypes.func
};


export default BackupCardWidget;
