import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { updateUserName, updateUserAddress, setField, addPhoneNumber, updateEmail } from '../actions/user-actions';
import { STEP } from '../reducers/user-reducer';
import { updateWalletHandle, updateTransactionAllocation } from '../actions/wallet-actions';
import { resetPassword } from '../actions/recover-password-actions';
import BasicLayout from './basic-layout';
import { OnDesktop, OnMobile } from '../components/breakpoints';
import PhoneNumber from '../components/phone-number';
import Button from '../components/button';
import NameModal from '../components/name-modal';
import PhoneModal from '../components/phone-modal';
import EmailModal from '../components/email-modal';
import ModalWalletHandle from '../components/wallet-settings/modal-wallet-handle';
import AddressModal from '../components/address-modal';
import Loader from '../components/loader';
import { setPaymentPopup } from '../actions/app-actions';

const INIT_STATE = {
  name: false,
  incomingTransactionAllocation: false,
  walletHandle: false,
  homeAddress: false,
  phoneNumber: false,
  email: false,
};

const WalletSettings = (props) => {
  const [modalsShown, setModalShown] = useState(INIT_STATE);
  const [activeTab, setActiveTab] = useState('personalInfo');

  useEffect(() => {
    if(!props.user.isUpdating) {
      initState();
    }
  }, [
    props.user.user.lname,
    props.user.user.fname,
    props.user.user.address,
    props.wallet.wallet,
    props.wallet.accounts
  ]);

  const initState = () => {
    setModalShown(INIT_STATE);
  };

  const toggleModal = (modalName) => {
    setModalShown({
      ...modalsShown,
      [modalName]: !modalsShown[modalName]
    });
  };

  const updateValues = (type) => {
    switch (type) {
      case 'email':
        return (value) => {
          props.updateEmail(value);
        };
      case 'phoneNumber': 
        return (value) => {
          props.addPhoneNumber(value);
        };
      case 'homeAddress':
        return (value) => {
          props.updateUserAddress(value);
        };
      case 'name':
        return (value) => {
          props.updateUserName(value);
        };
      case 'handle':
        return (value) => {
          props.updateWalletHandle(value);
        };
      case 'transactionAllocation':
        return (value) => {
          props.updateTransactionAllocation(value);
        };
      default:
        return null;
    }
  };

  const createAddressString = () => {
    const { user } = props.user;

    if (!user.address) {
      return null;
    }

    const local = [user.address.street1, user.address.street2].filter(val => !!val).join(', ');
    const glob = [
      user.address.city,
      user.address.state,
      user.address.zip,
    ].filter(val => !!val).join(', ');

    return (
      <Fragment>
        {local}
        {local && glob ? <br /> : null}
        {glob}
      </Fragment>
    );
  };

  const { user: { user, emailModalStep }, wallet, isGetting, gbUserName, emptyData, validationToken, isConfirmed } = props;

  const { fname, lname, address } = user;

  const canEditAddress = !user.address || (user.address && user.address.verified === false);
  const isUpdating = props.user.isUpdating || props.wallet.isUpdating;
  const promptForGB = validationToken && user.enabled_states &&
      user.enabled_states.prompt_for_gun_broker_wallet_link &&
      user.enabled_states.prompt_for_gun_broker_wallet_link.value;
  const showLinkedGB = !emptyData && isConfirmed;
  const showLinkGB = (promptForGB && emptyData) || (promptForGB && !emptyData && !isConfirmed);

  const personalInfo = (
    <Fragment>
      <div className="layer -space-up-md -space-down-xxl">
        <Button
          color="white"
          xSize="full"
          className="-has-arrow full-width"
          onClick={() => toggleModal('name')}
        >
          <div className="button_head">
            <div className="button_content-secondary">Name</div>
            <div className="button_content-primary">
              {user.fname} {user.lname}
            </div>
          </div>
        </Button>
        
        { canEditAddress &&
            (<Button
              color="white"
              xSize="full"
              className="-has-arrow full-width"
              onClick={() => toggleModal('homeAddress')}
            >
              <div className="button_head">
                <div className="button_content-secondary">Home Address</div>
                <div className="button_content-primary">
                  {createAddressString()}
                </div>
              </div>
            </Button>)
        }
        { !canEditAddress &&
            <Button
              color="white"
              xSize="full"
              className="full-width"
              disabled
            >
              <div className="button_head">
                <div className="button_content-secondary">Home Address</div>
                <div className="button_content-primary">
                  {createAddressString()}
                </div>
              </div>
            </Button>
        }

        <Button
          color="white"
          xSize="full"
          className="-has-arrow full-width"
          onClick={() => toggleModal('email')}
        >
          <div className="button_head">
            <div className="button_content-secondary">Email</div>
            <div className="button_content-primary">{user.email}</div>
          </div>
        </Button>

        <Button
          color="white"
          xSize="full"
          className="-has-arrow full-width"
          onClick={() => toggleModal('phoneNumber')}
        >
          <div className="button_head">
            <div className="button_content-secondary">Phone</div>
            <div className="button_content-primary">
              <PhoneNumber number={user.phone} isLink />
            </div>
          </div>
        </Button>
      </div>
      <div className="layer -space-up-md -space-down-md">
        <p className="color-secondary font-size-secondary-responsive margin-left-20">
            To update your personal information, please contact&nbsp;
          <a
            href={process.env.REACT_APP_HELP_DESK_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
              Customer Support
          </a>
        </p>
      </div>
    </Fragment>
  );

  const walletDetails = (
    <Fragment>
      <Button
        color="white"
        xSize="full"
        className="-has-arrow full-width"
        onClick={() => toggleModal('walletHandle')}
      >
        <div className="button_head">
          <div className="button_content-secondary">Wallet Handle</div>
          <div className="button_content-primary">{wallet.wallet.handle}</div>
        </div>
      </Button>

      <div className="layer -space-up-sm -space-down-lg">
        <p className="color-secondary font-size-secondary-responsive margin-left-20">
          Other users can send you funds using your handle
        </p>
      </div>

      {wallet.wallet.type === 'business' && (
        <Fragment>
          <div className="layer -space-up-xxl -space-down-xs">
            <h4>Business Info</h4>
          </div>

          <div className="well -color-white full-width">
            <div className="well_head">
              <div className="well_content-secondary">Business Name</div>
              <div className="well_content-primary">{wallet.wallet.name}</div>
            </div>
          </div>

          <div className="well -color-white full-width">
            <div className="well_head">
              <div className="well_content-secondary">EIN</div>
              <div className="well_content-primary">{wallet.wallet.ein}</div>
            </div>
          </div>

          <div className="layer -space-up-md">
            <p className="color-secondary font-size-secondary-responsive margin-left-20">
                To update your business information, please contact&nbsp;
              <a // eslint-disable-line jsx-a11y/anchor-is-valid
                href={process.env.REACT_APP_HELP_DESK_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                  Customer Support
              </a>
            </p>
          </div>
        </Fragment>
      )}
      { showLinkGB && (<div className="layer -space-up-xxl -space-down-xxl">
        <Button
          color="blue"
          xSize="full"
          className="page_control -cancel"
          onClick={() => {
            props.setField(['gbData', 'step'], STEP.LINK);

            props.setField(['gbData', 'popupIsOpened'], !props.gbPopupShown);
          }
          }>
                Link your GunBroker account
        </Button>
      </div>)
      }
      { showLinkedGB && (<div className="layer -space-up-xxl -space-down-xxl">
        <div className="layer -space-up-xxl">
          <h4>Linked Account</h4>
        </div>
        <Button
          xSize="full"
          className="-has-arrow full-width"
          onClick={() => {
            props.setField(['gbData', 'step'], STEP.UNLINK);
            props.setField(['gbData', 'popupIsOpened'], !props.gbPopupShown);
          }
          }>
          <div className="button_head">
            <div className="button_content-secondary">GunBroker</div>
            <div className="button_content-primary">{gbUserName}</div>
          </div>
        </Button>
      </div>)
      }
      <div className="layer -space-up-md">
        <Button
          color="white"
          xSize="full"
          className="-has-arrow full-width"
          onClick={() => { props.setPaymentPopup(true, false); }}
        >
          <div className="button_head">
            <div className="button_content-primary">Transactions limit</div>
          </div>
        </Button>
      </div>
    </Fragment>
  );

  return (
    <BasicLayout>
      {
        isGetting ? (<Loader size="sm" color="blue" className="-centered" />)
          : <Fragment>
            <OnMobile>
              <div className="page_upper-controls">
                <div className="tabs">
                  <div
                    className={cn('tabs_item', { '-active': activeTab === 'personalInfo' })}
                    onClick={() => setActiveTab('personalInfo')}
                  >
                    Personal Info
                  </div>
                  <div
                    className={cn('tabs_item', { '-active': activeTab === 'walletDetails' })}
                    onClick={() => setActiveTab('walletDetails' )}
                  >
                    Wallet Details
                  </div>
                </div>
              </div>

              {(activeTab === 'personalInfo') ? (
                <Fragment>
                  <div className="page_header">
                    <h1 className="page_title">Personal Info</h1>
                  </div>
                  <div className="page_body">
                    <div className="page_content">
                      {personalInfo}
                    </div>
                  </div>

                  <div className="page_controls -align-top-desktop">
                    <Button color="red" transparency="full" xSize="full" onClick={props.resetPassword} className="page_control -cancel">
                      Change Password
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="page_header">
                    <h1 className="page_title">Wallet Details</h1>
                  </div>
                  <div className="page_body">
                    <div className="page_content">
                      {walletDetails}
                    </div>
                  </div>
                </Fragment>
              )}
            </OnMobile>

            <OnDesktop>
              <div className="page_header">
                <h1 className="page_title">Personal Info</h1>
              </div>
              <div className="page_body">
                <div className="page_content">
                  <div className="layer ">
                    {personalInfo}
                  </div>

                  <div className="layer -space-down-xxxl">
                    <div onClick={props.resetPassword} className="color-red cursor-pointer margin-left-20">
                      Change Password
                    </div>
                  </div>

                  <div className="layer -space-up-sm -space-down-lg">
                    <h2 className="page_title -like-h1">Wallet Details</h2>
                  </div>

                  <div className="layer -space-up-md">
                    {walletDetails}
                  </div>
                </div>
              </div>
            </OnDesktop>
          </Fragment>}
        
      <PhoneModal
        shown={modalsShown.phoneNumber}
        onClose={() => toggleModal('phoneNumber')}
        updateValues={updateValues('phoneNumber')}
        isUpdating={isUpdating}
        closeButton
      />

      <EmailModal
        shown={modalsShown.email}
        onClose={() => toggleModal('email')}
        updateValues={updateValues('email')}
        isUpdating={isUpdating}
        step={ emailModalStep }
        setStep={value => props.setField(['emailModalStep'], value)}
        closeButton
      />

      <AddressModal
        shown={modalsShown.homeAddress}
        onClose={() => toggleModal('homeAddress')}
        address={ address }
        updateValues={updateValues('homeAddress')}
        isUpdating={isUpdating}
        closeButton
      />

      <NameModal
        shown={modalsShown.name}
        onClose={() => toggleModal('name')}
        name={{ fname, lname }}
        updateValues={updateValues('name')}
        isUpdating={isUpdating}
        closeButton
      />

      <ModalWalletHandle
        show={modalsShown.walletHandle}
        onClose={() => toggleModal('walletHandle')}
        updateValues={updateValues('handle')}
        closeButton
      />
    </BasicLayout>
  );
};

WalletSettings.propTypes = {
  wallet: PropTypes.shape(types.walletReducerTypes),
  user: PropTypes.shape(types.userReducerTypes),
  gbPopupShown: PropTypes.bool,
  isGetting: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  gbUserName: PropTypes.string,
  emptyData: PropTypes.bool,
  validationToken: PropTypes.string,
  isConfirmed: PropTypes.bool,
  updateUserName: PropTypes.func,
  updateWalletHandle: PropTypes.func,
  updateUserAddress: PropTypes.func,
  updateTransactionAllocation: PropTypes.func,
  resetPassword: PropTypes.func,
  setField: PropTypes.func,
  setPaymentPopup: PropTypes.func,
  addPhoneNumber: PropTypes.func,
  updateEmail: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    gbPopupShown: state.user.gbData.popupIsOpened,
    isGetting: state.user.gbData.isGetting,
    gbUserName: state.user.gbData.userName,
    emptyData: state.user.gbData.emptyData,
    validationToken: state.user.gbData.validationToken,
    isConfirmed: state.user.gbData.isConfirmed,
    wallet: state.wallet,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUserName,
    updateWalletHandle,
    updateUserAddress,
    updateTransactionAllocation,
    resetPassword,
    setField,
    setPaymentPopup,
    addPhoneNumber,
    updateEmail,
  },
)(WalletSettings);