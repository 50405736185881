import { filter, values, find, propEq } from 'ramda';
import { REGEXP_HANDLE } from './regexp';
import { formatNumberUSD, luhnCheck } from './string-utils';
import moment from 'moment';

const emailRegex = /\S+@\S+\.\S+/;
const phoneNumberRegex = /^(\d\d\d\d\d\d\d\d\d\d)$/;
const inltPhoneNumberRegex = /^(\d\d\d\d\d\d\d\d\d\d\d)$/;
const alphaDashSpaceRegexp = /^[a-z .,'-]+$/i;
const alphaRegex = /^([a-z][a-z])$/i;
const addressRegexp = /^[a-z \d.,'\-#()&]+$/i;
const zipRegex = /^(\d\d\d\d\d)$/;
const accountNumberRegex = /^[\da-z]{4,14}$/i;
const numberRegex = /^(\d){1,}$/;
const ssnRegex = /^(\d\d\d\d)$/;
const ssnFullRegex = /^(\d\d\d\d\d\d\d\d\d)$/;
const searchRegex = /^[a-z\d +.,'\-@]+$/i;
const onlyFalsyValues = value => !filter(val => !!val, values(value)).length;

const validateZip = (zip) => {
  if (!zip || zip.length < 1) {
    return {
      success: false,
      message: 'Cannot be blank',
    };
  }

  if (!zipRegex.test(zip)) {
    return {
      success: false,
      message: 'Must be 5 digits ',
    };
  }

  return {
    success: true,
  };
};

const validateState = (state) => {
  if (!state || state.length < 1) {
    return {
      success: false,
      message: 'State cannot be blank',
    };    
  }

  if (!alphaRegex.test(state)) {
    return {
      success: false,
      message: 'State must be 2 letters',
    };
  }

  return {
    success: true,
  };
};

const validateCity = (city) => {
  if (!city || city.length < 1) {
    return {
      success: false,
      message: 'City cannot be blank',
    };
  }

  if (city.length > 100 || !alphaDashSpaceRegexp.test(city)) {
    return {
      success: false,
      message: 'City must be 1–100 characters and contain only letters, spaces, or one of these symbols: . , \' -',
    };
  }

  return {
    success: true,
  };
};


const validateAddressLine2 = (address) => {
  if (!address || address.length < 1) {
    return {
      success: false,
      message: 'Address cannot be blank',
    };
  }

  if (address.length > 100|| !addressRegexp.test(address)) {
    return {
      success: false,
      message: 'Adress must be 1–100 characters and contain only letters, numbers, spaces, or one of these symbols: . , \' - # ( ) &',
    };
  }

  return {
    success: true,
  };
};

const validateAddressLine = (address) => {
  if (!address || address.length < 1) {
    return {
      success: false,
      message: 'Address cannot be blank',
    };
  }

  if (address.length > 255 || !addressRegexp.test(address)) {
    return {
      success: false,
      message: 'Address must be 1–255 characters and contain only letters, numbers, spaces, or one of these symbols: . , \' - # ( ) &',
    };
  }

  return {
    success: true,
  };
};

const validateBusinessName = (businessName) => {
  if (!businessName || businessName.length < 1) {
    return {
      success: false,
      message: 'Business name cannot be blank',
    };
  }

  if (businessName.length < 3) {
    return {
      success: false,
      message: 'Business name must be 3 or more characters',
    };
  }


  if (businessName.length > 100) {
    return {
      success: false,
      message: 'Business name must be 3–100 characters ',
    };
  }

  return {
    success: true,
  };
};

const validateFirstName = (fname) => {
  if (!fname || fname.length < 1) {
    return {
      success: false,
      message: 'First name cannot be blank',
    };
  }

  if (!alphaDashSpaceRegexp.test(fname) || fname.length > 60) {
    return {
      success: false,
      message: 'First name must be 1–60 characters and contain only letters, spaces, or one of these symbols: . , \' -',
    };
  }

  return {
    success: true,
  };
};

const validateLastName = (lname) => {
  if (!lname || lname.length < 1) {
    return {
      success: false,
      message: 'Last name cannot be blank',
    };
  }

  if (!alphaDashSpaceRegexp.test(lname) || lname.length > 60) {
    return {
      success: false,
      message: 'Last name must be 1–60 characters and contain only letters, spaces, or one of these symbols: . , \' -',
    };
  }

  return {
    success: true,
  };
};

const searchStringSource = (value) => {
  const isPhoneNumber = numberRegex.test(value);
  const isEmail = value.indexOf('@') !== -1;
  const isHandle = !isPhoneNumber && !isEmail;

  if (isPhoneNumber) {
    return {
      type: 'phone',
      rest: 'handle or email',
    };
  }

  if (isEmail) {
    return {
      type: 'email',
      rest: 'handle or phone',
    };
  }

  if (isHandle) {
    return {
      type: 'handle',
      rest: 'phone or email'
    };
  }
};

const validateSearchString = (value) => {
  const isPhoneNumber = numberRegex.test(value);
  const isEmail = value.indexOf('@') !== -1;
  const isHandle = !isPhoneNumber && !isEmail;

  if (!searchRegex.test(value) || value.length < 3 || value.length >= 100) {
    return {
      success: false,
      message: 'Must be 3–100 characters and contain only letters, numbers, spaces, or one of these symbols: . , \' - + @',
    };
  }

  if(isEmail) {
    if (validateEmail(value).success) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        message: 'This does not look like a valid email address',
      };
    }
  }

  if (isPhoneNumber) {
    if(phoneNumberRegex.test(value)) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        message: 'This does not look like a valid phone number',
      };
    }
  }

  if (isHandle) {
    if(validateHandle(value).success) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        message: 'This does not look like a valid handle, email, or phone number',
      };
    }
  }

  return {
    success: false,
    message: 'This does not look like a valid handle, email, or phone number',
  };
};

const validateEmail = (email) => {
  if (!emailRegex.test(email)) {
    return {
      success: false,
      message: 'This does not look like a valid email address',
    };
  }

  return {
    success: true,
  };
};

const getPasswordRequirenments = password => {
  return {
    len: password.length >= 8 && password.length <= 100,
    lowercase: /[a-z]/.test(password),
    uppercase: /[A-Z]/.test(password),
    digit: /[0-9]/.test(password),
    symbol: /[ .,<>/?;:'"{}\-_=+)(*&^%$#@!~`|[\]]/.test(password),
  };
};

const isPartOfEmail = (password, email) => {
  const emailArr = email && email.split('@');

  return email && emailArr.filter(
    eml => password && eml && password.toUpperCase().indexOf(eml.toUpperCase()) !== -1
  ).length > 0;
};

const validatePassword = (password, email) => {
  if (password.length < 8) {
    return {
      success: false,
      message: 'Password is too short',
    };
  }

  if (password.length > 100) {
    return {
      success: false,
      message: 'Password is too long',
    };
  }

  if (email && isPartOfEmail(password, email)) {
    return {
      success: false,
      message: 'You cannot use any part of your email as a password',
    };
  }

  let validationRules = 0;

  if (/[a-z]/.test(password)) {
    // contains at least one a-z
    validationRules += 1;
  }

  if (/[A-Z]/.test(password)) {
    // contains at least one A-Z
    validationRules += 1;
  }

  if (/[0-9]/.test(password)) {
    // contains at least one digit
    validationRules += 1;
  }

  if (/[ .,<>/?;:'"{}\-_=+)(*&^%$#@!~`|[\]]/.test(password)) {
    // contains at least one punctuation symbol
    validationRules += 1;
  }

  if (validationRules < 3) {
    return {
      success: false,
      message: `Passwords must use at 
        least three of the four available character types: 
        lowercase letters, uppercase letters, numbers, and symbols.`,
    };
  }


  return {
    success: true,
  };
};

const validatePasswordAndConfirmation = (password, confirmation) => {
  const validation = validatePassword(password);

  if (!validation.success) return validation;

  return password !== confirmation
    ? {
      success: false,
      message: 'Confirmation isn\'t equal to password',
    }
    : validation;
};


const validatePhoneNumber = (phoneNumber) => {
  const phRegex = process.env.REACT_APP_APPLICATION_ENV !== 'development'
    ? phoneNumberRegex
    : inltPhoneNumberRegex;

  if (!phoneNumber || !phRegex.test(phoneNumber)) {
    return {
      success: false,
      message: process.env.REACT_APP_APPLICATION_ENV !== 'development'
        ? 'Phone number must be 10 digits'
        : 'Phone number must be 11 digits',
    };
  }

  return {
    success: true,
  };
};

const validateSSN = (ssn) => {
  if (!ssnRegex.test(ssn)) {
    return {
      success: false,
      message: 'Must be 4 digits',
    };
  }

  return {
    success: true,
  };
};

const validateFullSSN = (ssn) => {
  if (!ssnFullRegex.test(ssn)) {
    return {
      success: false,
      message: 'Must be 9 digits',
    };
  }

  return {
    success: true,
  };
};

const validateNotEmpty = (field, value) => {
  if (onlyFalsyValues(value)) {
    return {
      success: false,
      message: `${field} can't be empty.`,
    };
  }
  return {
    success: true,
  };
};

const validateAccountUniqueness = (routing, number, bankAccountsList) => {
  const list = filter(propEq('routing_number', routing), bankAccountsList);
  if (list.length > 0 && find(propEq('account_number', number.slice(-4)), list)) {
    return {
      success: false,
      message: 'This account is already added',
    };
  }

  return {
    success: true,
  };
};

const validateAccountRouting = (routing) => {
  // Chris asked about this for testing purposes
  // https://bitrail.slack.com/archives/CC1K2GHFA/p1635521210030200
  if(routing === '021999921' || routing === '091222219') {
    return {
      success: true,
    };
  }

  if (routing.length !== 9) {
    return {
      success: false,
      message: 'Routing number must be 9 digits',
    };
  }

  const checksumTotal =
    (7 * (parseInt(routing.charAt(0), 10) + parseInt(routing.charAt(3), 10) + parseInt(routing.charAt(6), 10))) +
    (3 * (parseInt(routing.charAt(1), 10) + parseInt(routing.charAt(4), 10) + parseInt(routing.charAt(7), 10))) +
    (9 * (parseInt(routing.charAt(2), 10) + parseInt(routing.charAt(5), 10) + parseInt(routing.charAt(8), 10)));

  const checksumMod = checksumTotal % 10;

  if (checksumMod !== 0) {
    return {
      success: false,
      message: 'Invalid routing number',
    };
  }

  return {
    success: true,
  };
};

const validateAccountNumber = (bankAccountNumber) => {
  if (!bankAccountNumber.length) {
    return {
      success: false,
      message: 'Account number cannot be blank',
    };
  }
  //
  if (!accountNumberRegex.test(bankAccountNumber)) {
    return {
      success: false,
      message: 'Account number must be 4–14 characters and contain only numbers or letters',
    };
  }

  return {
    success: true,
  };
};

const validateAccountLabel = (bankAccountLabel, name) => {
  if (!bankAccountLabel.length) {
    return {
      success: false,
      message: `${name ? name : 'Nickname'} cannot be blank`,
    };
  }

  if (bankAccountLabel.length < 3) {
    return {
      success: false,
      message: `${name ? name : 'Nickname'} must be 3 or more characters`,
    };
  }


  if(bankAccountLabel.length > 30) {
    return {
      success: false,
      message: `${name ? name : 'Nickname'} must be 3–30 characters`,
    };
  }

  return {
    success: true,
  };
};

const validateAccountType = (bankAccountType) => {
  if (!bankAccountType.length) {
    return {
      success: false,
      message: 'Choose bank account type',
    };
  }

  return {
    success: true,
  };
};

const validateAccountClass = (bankAccountClass) => {
  if (!bankAccountClass.length) {
    return {
      success: false,
      message: 'Choose bank account class',
    };
  }

  return {
    success: true,
  };
};

const validateHandle = (handle) => {
  if (!handle.length) {
    return {
      success: false,
      message: 'Wallet handle cannot be blank',
    };
  }

  if (!REGEXP_HANDLE.test(handle)) {
    return {
      success: false,
      message: 'Handle must start with a letter, be 3–50 characters, and contain only letters, numbers, or -',
    };
  }

  return {
    success: true,
  };
};

const validateLoanOfferAmount = (minAmount, maxAmount, amount) => {
  if (parseFloat(amount) < minAmount) {
    return {
      success: false,
      message: `${formatNumberUSD(parseFloat(minAmount))} USD min`,
    };
  }

  if (parseFloat(amount) > maxAmount) {
    return {
      success: false,
      message: `${formatNumberUSD(parseFloat(maxAmount))} USD max`,
    };
  }

  return {
    success: true,
  };
};

const validateCardNumber = (cardNumber) => {
  if (!cardNumber || cardNumber.length < 1) {
    return {
      success: false,
      message: `Card number cannot be blank`,
    };
  }

  if (cardNumber.length < 12) {
    return {
      success: false,
      message: `Card number must be 12 or more characters`,
    };
  }

  if(!luhnCheck(cardNumber)) {
    return {
      success: false,
      message: 'Card number is not valid'
    };
  }

  return {
    success: true,
  };
};
const validateCardVerificationCode = (code) => {
  if (!code || code.length < 1) {
    return {
      success: false,
      message: `Cannot be blank`,
    };
  }

  if (code.length < 3) {
    return {
      success: false,
      message: `Must be 3 or 4 characters`,
    };
  }

  return {
    success: true,
  };
};

const validateCardName = (name, isFirstName) => {
  if (!name || name.length < 1) {
    return {
      success: false,
      message: `${isFirstName ? 'First' : 'Last'} name cannot be blank`,
    };
  }

  if (!name || name.length < 2) {
    return {
      success: false,
      message: `${isFirstName ? 'First' : 'Last'} name must be 2 or more characters`,
    };
  }

  return {
    success: true,
  };
};

const validateCardExpiration = (expiration) => {
  if (!expiration || expiration.length < 1) {
    return {
      success: false,
      message: `Cannot be blank`,
    };
  }

  const year = `20${expiration.split('/')[1].trim()}`;
  const month = expiration.split('/')[0].trim();

  if(parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
    return {
      success: false,
      message: `Month should be value between 1 and 12`,
    };
  }

  const now = moment().unix();
  const exp = moment().set('year', year).set('month', month).set('day', 1).unix();

  if(exp < now) {
    return {
      success: false,
      message: `Expiration date should be in future`,
    };
  }

  return {
    success: true,
  };
};


const validateCardLabel = (cardLabel) => {

  if(cardLabel.length > 30) {
    return {
      success: false,
      message: `Card label must be shorter than 30 characters`,
    };
  }

  return {
    success: true,
  };
};


export default {
  isPartOfEmail,
  getPasswordRequirenments,
  searchStringSource,
  validateCity,
  validateState,
  validateZip,
  validateAddressLine,
  validateAddressLine2,
  validateBusinessName,
  validateEmail,
  validatePassword,
  validatePasswordAndConfirmation,
  validatePhoneNumber,
  validateSSN,
  validateFullSSN,
  validateNotEmpty,
  validateAccountUniqueness,
  validateAccountRouting,
  validateAccountNumber,
  validateAccountLabel,
  validateAccountType,
  validateHandle,
  validateFirstName,
  validateLastName,
  validateSearchString,
  validateLoanOfferAmount,
  validateAccountClass,
  validateCardNumber,
  validateCardVerificationCode,
  validateCardName,
  validateCardExpiration,
  validateCardLabel
};
