import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Modal from '../modal';
import * as types from '../../constants/prop-types';
import Svg from '../svg';
import { getIconName } from '../../constants/card-account-constants';


const CreditCardBackup = ({ cardAccounts, setAsBackup, ...props }) => {

  const closeModal = () => {
    props.onClose();
  };

  return (
    <Modal {...props}>
      <div className="modal_header">
        <h1 className="js-modal-title">Choose backup payment card</h1>
      </div>
      <div className="modal_body">
        <p>Select a valid backup payment method. This will make payment processing instant</p>
        {Array.isArray(cardAccounts) && cardAccounts.length > 0 && (
          <div className="button-group full-width">
            <div className="button-group_body">
              {cardAccounts.map(card => (
                <Button
                  key={card.card_account_id}
                  xSize="full"
                  ySize="xl"
                  className="button-group_item -has-arrow"
                  color="white"
                  onClick={() => setAsBackup(card)}
                >
                  <div className="button_head">
                    <div className="button_content-primary js-nickname">{card.user_description}</div>
                    <div className="button_content-secondary">
                      <Svg name={getIconName(card.bank_name)} className="icon cards-page__icon" />
                      <span className="account-number-bullets">&bull;&bull;</span>{card.account_number}&nbsp;
                      {card.is_default_backup && (
                        <div className="label">Default backup</div>
                      )}
                    </div>
                  </div>
                </Button>
              ))}
              <Button
                xSize="full"
                ySize="xl"
                className="button-group_item order-backup__without-backup"
                color="white"
                onClick={() => setAsBackup({ card_account_id: null, is_default_backup: true })}
              >
                <div className="button_head">
                  <div className="button_content-primary js-nickname">Continue with no backup</div>
                  <div className="button_content-secondary">
                    Payment will take up to 10 business days
                  </div>
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            disabled={props.isLoading}
            onClick={closeModal}
          >
              Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CreditCardBackup.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  setField: PropTypes.func,
  setAsBackup: PropTypes.func.isRequired,
  cardAccounts: PropTypes.arrayOf(types.CreditCardShape),
};

CreditCardBackup.defaultProps = {
  isLoading: false,
  cardAccounts: [],
  setField: () => {}
};

export default CreditCardBackup;
