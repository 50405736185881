import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginLayout from '../../pages/log-in-layout';
import FormInput from '../form-input';
import Button from '../button';
import qs from 'qs';
import CONST from '../../constants/login-constants';
import { redirectToOAuthLogin } from '../../utils/redirects';

const LoginStepEmailPassword = ({ login, endStep, setField }) => {

  useEffect(() => {
    const { avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if(!avi) {
      redirectToOAuthLogin();
    }
  }, []);

  const submit = event => {
    event.preventDefault();

    if (login.password && login.email) {
      endStep(CONST.STEP_EMAIL_PASSWORD);
    }
  };

  const signupLink = login.isOAuth ? `/signup${window.location.search}` : '/signup';

  const { client_name } = qs.parse(window.location.search, { ignoreQueryPrefix: true });


  return (
    <LoginLayout
      bgClassName="-full"
      headerClassName="-y-xl -logo-only -logo-big"
      wrapperClassName="-x-sm -content-x-centered-desktop -content-y-centered"
      twoLiner
      isBigLogo
      poweredByType="sign"
    >
      <div className="page_header -x-centered-desktop -login">
        <div className="page_header -space-between -space-sm">
          <h1 className="page_title">
          Log In 
          </h1>
        </div>
        {client_name ? (
          <div className="layer">
            <p className="font-size-secondary-responsive color-light-gray">
            to authorize your BitRail account to {client_name}
            </p>
          </div>
        ) : null}
      </div>
      <div className="page_body">
        <div className="page_content">
          <form
            className="form -login-page-form"
            onSubmit={submit}
          >
            <input type="submit" className="form-input_invisible" onSubmit={submit} />
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Email"
                  value={login.email}
                  onChange={value => setField('email', value)}
                  inputName="login-email"
                />
              </div>
            </div>

            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Password"
                  type="password"
                  value={login.password}
                  onChange={value => setField('password', value.substr(0, 100))}
                  inputName="login-password"
                  errorMessage={login.errors.general}
                />
              </div>
            </div>
            <div className="layer -space-up-md -col">
              <Link to="/recover-password">Forgot password</Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
                Help
              </a>
            </div>
          </form>
        </div>
      </div>

      <div className="page_controls">
        <div className="page_control font-weight-bold">
          <span className='color-light-gray'>
            {'Do not have an account? '}
            <a href={signupLink}>Sign Up</a>
          </span>
        </div>
        <Button
          color="blue"
          xSize="full"
          loading={login.isLoading}
          className="js-submit-button page_control -submit"
          onClick={submit}
          disabled={!login.password || !login.email}
        >
            Log In
        </Button>
      </div>
    </LoginLayout>
  );
};

LoginStepEmailPassword.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  login: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    isLoading: PropTypes.bool,
    errors: PropTypes.shape({
      general: PropTypes.string,
    }),
    isOAuth: PropTypes.bool
  }),
};

export default LoginStepEmailPassword;
