import { stringify } from 'qs';
import json2Form from 'form-urlencoded';
import 'whatwg-fetch';
import { isEmpty, startsWith } from 'ramda';
import checkResponse from './check-response';
import { redirectToOAuthLogin, redirectTo404 } from './redirects';
import store from '../init-store';
import { addNotification } from '../actions/toast-actions';
// import { refreshOAuthToken } from '../actions/login-actions';
import { formatErrorMessage } from '../utils/string-utils';
import { isBackupCardError } from './send-money-utils';
import storage from 'sessionstorage';
import qs from 'qs';
import { history } from '../init-store';

export const basePath = process.env.REACT_APP_API_URL || 'https://private-31d4f-bitrail.apiary-mock.com';
export const cdnPath = 'https://cdn.bitrail.io';
export const helpDeskUrl = 
  `<a target="_blank" class="notification-link" href="${
    process.env.REACT_APP_HELP_DESK_URL
  }">Customer Support</a>`;

const DOMAINS = {
  PAYGENIUS: 'paygenius',
  SHOTWALLET: 'shotwallet',
};

export const CLIENT_ID = (() => {
  const { host } = window.location;

  if(host.includes(DOMAINS.PAYGENIUS)) {
    return process.env.REACT_APP_PG_CLIENT_ID;
  }

  if(host.includes(DOMAINS.SHOTWALLET)) {
    return process.env.REACT_APP_SW_CLIENT_ID;
  }

  return process.env.REACT_APP_CLIENT_ID;
})();

export const CLIENT_SECRET = (() => {
  const { host } = window.location;

  if(host.includes(DOMAINS.PAYGENIUS)) {
    return process.env.REACT_APP_PG_CLIENT_SECRET;
  }

  if(host.includes(DOMAINS.SHOTWALLET)) {
    return process.env.REACT_APP_SW_CLIENT_SECRET;
  }

  return process.env.REACT_APP_CLIENT_SECRET;
})();

const pathsWithBasic = ['/v1/auth/forgot_password'];

export const getLogoUrl = (avi, size = 'large', type = 'svg') => 
  `${basePath}/v1/clients/${CLIENT_ID}/options/img/${size}/${type}/logo.${type}?avi=${avi}&pg=${window.location.pathname}`;


export function setInitializedMethod() {
  Object.defineProperty(window, '_isIFrameCall', {
    value: (() => {
      switch (window.location.pathname) {
        case '/order/form':
        case '/orders/form':
        case '/agreements/form': return true;
        default: return false;
      }
    })(),
    writable: false,
    enumerable: false,
    configurable: false,
  });
}

export function setAuthToken(token) {
  try {
    window.localStorage.setItem('token', token);
  } catch (e) {
    storage.setItem('token', token);
  }
}

export function setRefreshToken(token) {
  try {
    window.localStorage.setItem('refreshToken', token);
  } catch (e) {
    storage.setItem('refreshToken', token);
  }
}

export function setScopes(scopes) {
  try {
    window.localStorage.setItem('scopes', scopes);
  } catch (e) {
    storage.setItem('scopes', scopes);
  }
}

export function clearAuthToken() {
  try {
    window.localStorage.clear();
  } catch (e) {
    storage.clear();
  }
}

function getHeaders(partialUrl, data = {}, clientui) {
  const headers = new Headers();
  let token = '';
  const { avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if(clientui) {
    headers.append('Authorization', `Bearer ${clientui}`);
  } else if(!data.avi && !avi) {
    try {
      token = window.localStorage.getItem('token');
    } catch (e) {
      token = storage.getItem('token');
    }

    if (pathsWithBasic.find(path => partialUrl.indexOf(path) !== -1)) {
      const basicToken =  btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);
  
      headers.append('Authorization', `Basic ${basicToken}`);
    } else {
      headers.append('Authorization', `Bearer ${token}`);
    }
  } else {
    headers.append('Authorization', `Bearer ${avi}`);
  }

  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  return headers;
}

export const is4xx = (status) => {
  return [401, 404, 408].indexOf(status) === -1 && status >= 400 && status < 500;
};

const dispatch500 = () => {
  store.dispatch(addNotification(`Something went wrong. Please try again or 
    contact ${helpDeskUrl} if the error persists.`, 500));
};

const handle401 = () => {
  // const refreshToken = localStorage.getItem('refreshToken');
  // if(refreshToken) {
  //   store.dispatch(refreshOAuthToken());
  // } else {
  window.stop();
  clearAuthToken();
  redirectToOAuthLogin();
  // }
};

const responseHandler = async (method, url, response, location) => {
  // here will be error handling
  const isSignup = startsWith('/login', location)
    || startsWith('/signup', location)
    || startsWith('/partner-signup', location);

  const isGbSignup = startsWith('/setup/streamlined', location);

  const gunbrokerCall = /\/v1\/users\/\S{1,}\/gun_broker\/\S{1,}/gi.test(url);
  const isSetNewPassword = startsWith('/set-new-password', location);

  let data = {};

  if(response.redirected && response.url) {
    const redirectUrl = new URL(response.url);
    if(redirectUrl.pathname === window.location.pathname ||
      redirectUrl.origin === 'https://app.qa.bitrail.io' ||
      redirectUrl.origin === 'https://app.dev.bitrail.io') {
      window.location.assign(`${redirectUrl.pathname}${redirectUrl.search}`);
      // history.push(`${redirectUrl.pathname}${redirectUrl.search}`);
    } else {
      window.location.assign(`${redirectUrl.origin}${redirectUrl.pathname}${redirectUrl.search}`);
    }
  }
  
  if (!isGbSignup) {
    if (!isSetNewPassword) {
      if (isSignup) {
        switch (response.status) {
          case 401:
            if (!startsWith('/login', location) || url !== '/v1/auth/token') {
              handle401();
            }
            break;
          case 403:
            if (!startsWith('/partner-signup', location)) {
              handle401();
            }

            break;

          case 408:
            history.push('/maintenance');
            break;  
        
          default: break;
        }
      } else {
        switch (response.status) {
          case 401:
            handle401();
            break;

          case 403:
            if (window._isIFrameCall) {
              handle401();
            }
            break;

          case 404:
            if(response.url.includes('/authcode')) {
              break;
            } else {
              redirectTo404();
              break;
            }
          case 408:
            history.push('/maintenance');
            break;

          default: break;
        }
      }
    
      if (response.status >= 500) {
        dispatch500();
      };
    }

    try {
      data = await response.json();   
    } catch (e) {
      data = {};
    }
    
    if (!isSignup && is4xx(response.status) && !isSetNewPassword && (!gunbrokerCall || response.status !== 403)) {
      if(response.status === 408) {
        history.push('/maintenance');
      } else if (data.errors && response.status !== 404 && !isBackupCardError(data.errors)) {
        data.errors.map(
          error => store.dispatch(addNotification(formatErrorMessage(error)))
        );
      } else if(!isBackupCardError(data.errors)) {
        store.dispatch(addNotification(`Something went wrong. Please try again or 
          contact Customer Support if the error persists.`));
      }
    };

    if (process.env.REACT_APP_APIARY_CHECKER) {
      checkResponse(method, url, response, data);
    }
  } else {
    try {
      data = await response.json();  
    } catch (e) {
      data = {
        success: false
      };
    }
  }

  // add to request status to data for checking it in sagas
  data.status = response.status;
  data.url = response.url;
  data.redirected = response.redirected;
  data.data = !data.data ? [] : data.data;

  return data;
};

export const GET = (partialUrl, opts = {}, clientui) =>
  async function () {
    const query = isEmpty(opts) ? '' : `?${stringify(opts)}`;

    const request = new Request(basePath + partialUrl + query, {
      method: 'GET',
      headers: getHeaders(partialUrl, clientui),
      qs: opts,
    });

    const location = window.location.pathname;
    const isSetNewPassword = startsWith('/set-new-password', location);

    let response = {};

    try {
      response = await fetch(request);  
    } catch (error) {
      if (!error.response && !isSetNewPassword) {
        dispatch500();
      }
    }

    return await responseHandler('GET', partialUrl, response, location);
  };

export const PUT = (partialUrl, data) =>
  async function () {
    const request = new Request(basePath + partialUrl, {
      method: 'PUT',
      headers: getHeaders(partialUrl),
      body: json2Form(data),
    });

    const location = window.location.pathname;
    const isSetNewPassword = startsWith('/set-new-password', location);

    let response = {};

    try {
      response = await fetch(request);  
    } catch (error) {
      if (!error.response && !isSetNewPassword) {
        dispatch500();
      }
    }

    return await responseHandler('PUT', partialUrl, response, location);
  };

export const POST = (partialUrl, data) =>
  async function () {
    const request = new Request(basePath + partialUrl, {
      method: 'POST',
      headers: getHeaders(partialUrl, data),
      body: json2Form(data),
    });

    const location = window.location.pathname;
    const isSetNewPassword = startsWith('/set-new-password', location);
    const isGbSignup = startsWith('/gb-signup', location);

    let response = {};

    try {
      response = await fetch(request);  
    } catch (error) {
      if (!error.response && !isSetNewPassword && !isGbSignup) {
        dispatch500();
      }
    }
    
    return await responseHandler('POST', partialUrl, response, location);
  };

export const DELETE = partialUrl =>
  async function () {
    const request = new Request(basePath + partialUrl, {
      method: 'DELETE',
      headers: getHeaders(partialUrl),
    });

    const location = window.location.pathname;
    const isSetNewPassword = startsWith('/set-new-password', location);

    let response = {};

    try {
      response = await fetch(request);  
    } catch (error) {
      if (!error.response && !isSetNewPassword) {
        dispatch500();
      }
    }

    return await responseHandler('DELETE', partialUrl, response, location);
  };
