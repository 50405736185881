import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from '../components/header';

const LoginLayout = (props) => {
  const {
    children,
    headerClassName,
    wrapperClassName,
    hideNav,
    twoLiner,
    isBigLogo,
    poweredByType,
  } = props;

  return (
    <div className="page -login">
      
      <Header
        className={cn('-logo-only', headerClassName, twoLiner ? '-twoliner' : null)}
        hideNav={hideNav}
        isBigLogo={isBigLogo}
        isBranding={true}
        poweredByType={poweredByType}
      />
      <div className={cn('page_wrapper', wrapperClassName)}>
        <div className="page_wrapper-inner">
          {children}
        </div>
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  twoLiner: PropTypes.bool,
  hideNav: PropTypes.bool,
  isBigLogo: PropTypes.bool,
  poweredByType: PropTypes.oneOf(['full', 'sign']),
};

LoginLayout.defaultProps = {
  children: null,
  bgClassName: null,
  headerClassName: null,
  wrapperClassName: null,
  twoLiner: false,
  gbPayLogo: false,
  poweredByType: 'full',
};

export default LoginLayout;
