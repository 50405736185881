import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import CreditCardsAddPage from '../../pages/credit-cards-add';


const CreditCardAddModal = (props) => {

  return (
    <Modal {...props}>
      <CreditCardsAddPage
        headerClassName="cards-page__hidden"
        wrapperClassName="cards-page__backup-modal"
        onClose={props.onClose}
      />
    </Modal>
  );
};

CreditCardAddModal.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

CreditCardAddModal.defaultProps = {
  isLoading: false,
  cardAccounts: [],
};

export default CreditCardAddModal;
