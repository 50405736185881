export const CONSTANTS = {
  GET_CURRENT_WALLET: '@wallet/GET_CURRENT_WALLET',
  SET_CURRENT_WALLET: '@wallet/SET_CURRENT_WALLET',
  SET_WALLET_BANK_ACCOUNTS: '@wallet/SET_WALLET_BANK_ACCOUNTS',
  SET_WALLET_CARD_ACCOUNTS: '@wallet/SET_WALLET_CARD_ACCOUNTS',
  SET_WALLET_ACCOUNTS: '@wallet/SET_WALLET_ACCOUNTS',
  SET_FIELD: '@wallet/SET_FIELD',
  UPDATE_WALLET_HANDLE: '@wallet/UPDATE_WALLET_HANDLE',
  UPDATE_TRANSACTION_ALLOCATION: '@wallet/UPDATE_TRANSACTION_ALLOCATION',
  SET_ERROR_MESSAGE: '@wallet/SET_ERROR_MESSAGE',
  FETCH_ACCOUNTS: '@wallet/FETCH_ACCOUNTS',
  ACCOUNT_TYPE_FREEDOMCOIN: 'freedomcoin',
  ACCOUNT_TYPE_USD: 'usd',
  GET_RATE: '@wallet/GET_RATE',
  SET_RATE: '@wallet/SET_RATE',
  UPDATE_EIN: '@wallet/UPDATE_EIN',
};

export default CONSTANTS;
