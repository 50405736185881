import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import Loader from '../components/loader';
import cn from 'classnames';
import { filter } from 'ramda';
import { connect } from 'react-redux';
import BasicLayout from './basic-layout';
import Checkbox from '../components/checkbox';
import Button from '../components/button';
import Modal from '../components/modal';
import { setField, cancelPayment, saveRecurrentPayment } from '../actions/recurrent-payment-actions';
import LoginLayout from '../pages/log-in-layout';
import Svg from '../components/svg';
import { defaultAccountSelector } from '../reducers/wallet-reducer';
import { getAccountBalance } from '../utils/send-money-utils';
import { canUseACHSelector } from '../reducers/app-reducer';

const ErrorPage = ({ errors, cancelPayment }) => {
  return (
    <LoginLayout
      headerClassName="-y-lg"
      hideNav
    >
      <div className="page_header -x-centered-desktop">
      </div>
      <div className="page_body">
        <div className="page_content">
          <div
            className="form -login-page-form"
          >
            <div className="layer -space-down-sm">
              <div className="page_splash splash">
                <Svg name="circled-cross" className="splash_icon" />
                <h3 className="js-page-title splash_title -small">Sorry, we can not enable recurring withdrawal for your wallet at the moment</h3>
              </div>
            </div>

            <div className="layer">
              <p className="font-size-secondary-responsive -clean-box">
                { errors.map((errText, idx) => (
                  <Fragment key={errText}>
                    {errText}
                    <br />
                    { idx !== errors.length - 1 ? (<br />) : null }
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <div className="page_control font-weight-bold">
          <Button
            transparency="full"
            href={process.env.REACT_APP_DOMAIN_NAME === 'GB_PAY' ? 'https://app.gunbrokerpay.com' : 'https://app.bitrail.io'}
            target="_blank"
          >
              Log In to {process.env.REACT_APP_DOMAIN_STRING} for further instructions

          </Button>
        </div>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={() => cancelPayment()}

        >
            Choose other payment method
        </Button>
      </div>
    </LoginLayout>
  );
};

ErrorPage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  cancelPayment: PropTypes.func
};

const BankAccountsModal = ({ banks, shown, onClose, onSelect }) => {
  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1>Choose Bank Account</h1>
      </div>
      <div className="modal_body">
        <div className="button-group full-width">
          <div className="button-group_body">
            {banks.map(bank => (
              <Button
                key={bank.bank_account_id}
                xSize="full"
                ySize="xl"
                className="button-group_item"
                color="white"
                onClick={() => onSelect(bank)}
              >
                <div className="button_head">
                  <div className="button_content-primary js-nickname">{bank.user_description}</div>
                  <div className="button_content-secondary">
                    {bank.bank_name || 'Bank Account'}&nbsp;
                    {bank.account_type === 'savings' && (
                      <div className="label">Savings</div>
                    )}
                  </div>
                </div>
                <div className="button_tail">
                  <div className="button_content-primary font-weight-regular">
                    <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="modal_footer-control -cancel"
            onClick={onClose}
          >
          Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

BankAccountsModal.propTypes = {
  banks: PropTypes.arrayOf(types.BankAccountShape),
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};

const AccountsModal = ({ accounts, shown, onClose, onSelect }) => {
  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1>Choose Your Account</h1>
      </div>
      <div className="modal_body">
        <div className="button-group full-width">
          <div className="button-group_body">
            {console.log(accounts)}
            {accounts.map(account => (
              <Button
                key={account.account_id}
                xSize="full"
                ySize="xl"
                className="button-group_item"
                color="white"
                onClick={() => onSelect(account)}
              >
                <div className="button_head">
                  <div className="button_content-primary">
                    {account.name} Account
                  </div>
                  <div className="button_content-secondary">
                    Balance:&nbsp;{getAccountBalance(account)}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="modal_footer-control -cancel"
            onClick={onClose}
          >
          Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AccountsModal.propTypes = {
  accounts: PropTypes.arrayOf(types.AccountShape),
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};

const SavePage = ({
  accounts,
  destWalletHandle,
  setField,
  usdAccIsUsed,
  bankModalShown,
  accountModalShown,
  setBankModalShown,
  setAccountModalShown,
  selectedAccount,
  selectedBankAccount,
  saveRecurrentPayment,
  banks,
  cancelPayment,
  paymentInProcess,
  description,
  isBankAccountsDisabled
}) => {
  return (<BasicLayout
    hideNav
    headerClassName="-payment"
    isBranding={true}
    poweredByType="full"
    wrapperClassName={
      cn('-no-page-header')
    }
  >
    <div className="page_header">
      <h1 className="page_title">Allow recurring withdrawal</h1>
    </div>

    <div className="page_body">
      <div className="page_content">
        <div className="layer -space-down-sm">
          <div className="badge -as-form-message -alert full-width">
            The recipient will be able to charge your accounts listed below
          </div>
        </div>

        <div className="description-list full-width -fixed-title">
          <div className="description-list_body">
            <div className="description-list_group">
              <div className="description-list_item">
                <div className="description-list_title">
                  Recipient
                </div>
                <div className="description-list_value">
                  {destWalletHandle}
                </div>
              </div>

              <div className="description-list_item">
                <div className="description-list_title">
                  Description
                </div>
                <div className="description-list_value">
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="description-list full-width">
          <div className="description-list_header">
            <div className="description-list_header-item">Payment sources</div>
          </div>
        </div>

        {!isBankAccountsDisabled  && (
          <div className="layer -space-down-sm">
            <div className="description-list full-width">
              <div className="description-list_header">
                <Checkbox
                  inputName="use-usd"
                  label={
                    <span>
                      Attempt to use funds in my BitRail Digital Wallet Account
                    </span>
                  }
                  className="-darken -gray -bordered -use-blue-mobile"
                  checked={usdAccIsUsed}
                  disabled={paymentInProcess}
                  onChange={({ target: { checked } }) => { setField('usdAccIsUsed', checked);  setAccountModalShown(checked);  }}
                />
              </div>
            </div>
          </div>
        )}
        

        {usdAccIsUsed && (
          <div className="button-group -titled -has-arrows full-width" style={{ 'marginBottom': '20px' }}>
            <div className="button-group_body">
              <Button
                key={selectedAccount.account_id}
                xSize="full"
                ySize="xl"
                className="button-group_item -has-arrow -arrow-blue"
                color="white"
                onClick={() => setAccountModalShown(true)}
              >
                <div className="button_head">
                  <div className="button_content-primary">
                    {selectedAccount.name} Account
                  </div>
                  <div className="button_content-secondary">
                    Balance:&nbsp;{getAccountBalance(selectedAccount)}
                  </div>
                </div>
              </Button>
            </div>
          </div>
        )}

        {!isBankAccountsDisabled && (
          <div className="button-group -titled -has-arrows full-width">
            <div className="button-group_body">
              <Button
                color="white"
                xSize="full"
                ySize="xl"
                className="button-group_item -has-arrow -arrow-blue"
                disabled={paymentInProcess}
                onClick={() => setBankModalShown(true)}
              >
                <div className="button_head">
                  <div className="button_content-primary">Bank Account</div>
                  <div className="button_content-secondary">
                    {selectedBankAccount.user_description || 'Account Number'}:
                    {' '}
                    <span className="nowrap">
                      <span className="account-number-bullets">&bull;&bull;</span>{selectedBankAccount.account_number}
                    </span>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        )}
        
        <div className="layer -space-up-md">
          { usdAccIsUsed
            ? <p className="color-secondary font-size-secondary-responsive">
                We will attempt to charge your {selectedAccount.name} account{isBankAccountsDisabled ? '.' :
                ', if there is not enough funds, we will use Bank account you selected'}
            </p>
            : <p className="color-secondary font-size-secondary-responsive">Your Bank account will be used for the payment</p>
          }
        </div>
      </div>
    </div>
      
    <div className="page_controls -align-top-desktop">
      <Button
        xSize="full"
        transparency="full"
        className="page_control -cancel"
        onClick={() => cancelPayment()}
        disabled={paymentInProcess}
      >
        Cancel
      </Button>
    
      <Button
        color="blue"
        xSize="full"
        className="page_control -submit"
        onClick={() => saveRecurrentPayment()}
        disabled={paymentInProcess}
        loading={paymentInProcess}
      >
        Save
      </Button>
    </div>

    <BankAccountsModal
      shown={bankModalShown}
      onClose={() => setBankModalShown(false)}
      banks={banks}
      onSelect={ bankAcc => {
        setField('selectedBankAccount', bankAcc);
        setBankModalShown(false);
      }}
    />

    <AccountsModal 
      shown={accountModalShown}
      onClose={() => setAccountModalShown(false)}
      accounts={accounts}
      onSelect={ account => {
        setField('selectedAccount', account);
        setAccountModalShown(false);
      }}
    />
  </BasicLayout>);
};

SavePage.propTypes = {
  accounts: types.walletReducerTypes.accounts,
  destWalletHandle: types.recurrentPaymentReducerTypes.destWalletHandle,
  setField: PropTypes.func,
  usdAccIsUsed: types.recurrentPaymentReducerTypes.usdAccIsUsed,
  bankModalShown: PropTypes.bool,
  accountModalShown: PropTypes.bool,
  setBankModalShown: PropTypes.func,
  setAccountModalShown: PropTypes.func,
  selectedAccount: types.AccountShape,
  selectedBankAccount: types.BankAccountShape,
  saveRecurrentPayment: PropTypes.func,
  banks: PropTypes.arrayOf(types.BankAccountShape),
  cancelPayment: PropTypes.func,
  paymentInProcess: types.recurrentPaymentReducerTypes.paymentInProcess,
  description: types.recurrentPaymentReducerTypes.description,
  isBankAccountsDisabled: PropTypes.bool
};

const RecurrentPayment = ({ 
  bankAccounts,
  accounts,
  recurrentPayment,
  setField,
  saveRecurrentPayment,
  wallet, cancelPayment,
  defaultAccount,
  isBankAccountsDisabled
}) => {
  const { selectedBankAccount, selectedAccount, usdAccIsUsed, isLoading, paymentInProcess } = recurrentPayment;

  const banks = filter(b => b.amounts_verified)(bankAccounts);
  const {
    destWalletHandle,
    amount,
    currency,
    description,
  } = recurrentPayment;


  useEffect(() => {
    !isBankAccountsDisabled && setField('selectedBankAccount', banks[0] || {});
    isBankAccountsDisabled === true && setField('usdAccIsUsed', true);
    setField('selectedAccount', accounts[0] || {});
  }, [bankAccounts, accounts]);

  const [ bankModalShown, setBankModalShown ] = useState(false);
  const [ accountModalShown, setAccountModalShown ] = useState(false);

  if (isLoading) {
    return (
      <div className="page -basic">
        <div className="page_wrapper -y-full -content-x-centered -content-y-centered">
          <div className="page_wrapper-inner">
            <div className="page_body">
              <div className="page_content">
                <Loader color="blue" size="sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let errors = [];

  if (!wallet.identity_verified || wallet.status !== 'active') {
    errors.push('Your account should be verified and active');
  }

  if (filter(b => b.withdraw_status === 'allowed')(banks).length === 0) {
    errors.push('You need at least one confirmed bank account, that has withdrawal allowed');
  }

  const paymentIsAllowed = !errors.length;

  if (paymentIsAllowed) {
    return (
      <SavePage
        accounts={accounts}
        destWalletHandle={destWalletHandle}
        amount={amount}
        description={description}
        currency={currency}
        setField={setField}
        usdAccIsUsed={usdAccIsUsed}
        setBankModalShown={setBankModalShown}
        setAccountModalShown={setAccountModalShown}
        selectedAccount={selectedAccount}
        selectedBankAccount={selectedBankAccount}
        saveRecurrentPayment={saveRecurrentPayment}
        bankModalShown={bankModalShown}
        accountModalShown={accountModalShown}
        isBankAccountsDisabled={isBankAccountsDisabled}
        banks={banks}
        cancelPayment={cancelPayment}
        paymentInProcess={paymentInProcess}
        defaultAccount={defaultAccount}
      />
    );
  }

  return (<ErrorPage errors={errors} cancelPayment={cancelPayment}/>);
};

RecurrentPayment.propTypes = {
  accounts: types.walletReducerTypes.accounts,
  bankAccounts: types.walletReducerTypes.bankAccounts, 
  recurrentPayment: PropTypes.shape(types.recurrentPaymentReducerTypes),
  setField: PropTypes.func,
  saveRecurrentPayment: PropTypes.func,
  wallet: types.walletReducerTypes.wallet,
  cancelPayment: PropTypes.func,
  defaultAccount: types.AccountShape,
  isBankAccountsDisabled: PropTypes.bool
};

const mapStateToProps = state => ({
  accounts: state.wallet.accounts,
  bankAccounts: state.wallet.bankAccounts,
  wallet: state.wallet.wallet,
  recurrentPayment: state.recurrentPayment,
  permissions: state.user.user.enabled_states,
  defaultAccount: defaultAccountSelector(state),
  isBankAccountsDisabled: canUseACHSelector(state)
});

export default connect(mapStateToProps, { setField, saveRecurrentPayment, cancelPayment })(RecurrentPayment);